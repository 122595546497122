import React from 'react';
import { Box, FormControl, Tab, Tooltip, Snackbar, Alert, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Banner from '../../../assets/Images/ecrawl-banner.png';
import { PostAdd, Close, FormatListBulleted } from '@mui/icons-material';
import ECrawlInput from './_ecrawlInputForm';
import { GetDefaultStateForSite, GetStringNameForSite } from '../../../interfaces/config.interface';
import { SiteName } from '../../../services/config.service';
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, 
    ExcelExportProperties,
    Resize,
    Reorder,
    SortSettingsModel,
    CommandColumn,
    setChecked,
    Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { getUser, isUserPermission } from '../../../services/auth.service';
import { Account } from '../../../interfaces/auth_interface';
import './ecrawl-styles.css';
import { eCRAWL } from '../../../interfaces/eCRAWL.interface';
import BTPersonReport from '../../shared/_BTPersonReport';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetEcrawl, SetEcrawl } from '../../../services/ecrawl.service';
import POIAttributes from '../../../interfaces/POI.interface';
import ECrawl from '../../../tools/ecrawl/ecrawl';
import { RunSetPOIList } from '../../../services/account.service';
import CloseIcon from '@mui/icons-material/Close';
import { dialogOpen } from '@syncfusion/ej2-react-richtexteditor';

interface ECrawlSearchResultsProps {
    Records: eCRAWL[]
    RefreshTrigger: any
} 
const ECrawlSearchResults: React.FC<ECrawlSearchResultsProps> = ({ Records, RefreshTrigger}) => {
    // const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    const [formatData, setFormatData] = React.useState<any>([])
    const [tabValue, setTabValue] = React.useState('2');
    const [tabName, setTabName] = React.useState('CREATE NEW');
    const [user, setUser] = React.useState<Account>(getUser())
    const [initialLoading, setInitialLoading] = React.useState(false);
    const [editingRecord, setEditingRecord] = React.useState<null>(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogText, setDialogText] = React.useState<string>("")   
    const [optionDialog, setOptionDialog] = React.useState<string>("")   
        
    let grid: Grid | null;
    let selectedRows: any[] = [];
 
    
    React.useEffect(() => {
            if (!initialLoading) {
                setInitialLoading(true);
            }
        }, [initialLoading])   
    

     const handleTabStateChange = (tab: any, edit: boolean = false) => {
            if (tab === '2') {
                setTabName('CREATE NEW RECORD')
                setEditingRecord(null)
            } else if (tab === '1') {
                if (!edit) {
                    setEditingRecord(null)
                    setTabName('CREATE NEW RECORD')
                } else {
                    setTabName('EDIT RECORD')
                }
            }
    
            setTabValue(tab)
        }
    
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        handleTabStateChange(newValue)
    }
    
    const handleDataTyping = () => {
        if (Records) {
            let tempP: any = [...Records]           
            if (grid){
                grid.dataSource = tempP
            }
            //reset NCIClist if they search again
            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.Start_Date) {
                    p.Start_Date = new Date(p.Start_Date)
                }
                if (p.End_Date) {
                    p.End_Date = new Date(p.End_Date)
                }
            }
            setFormatData(tempP)
            handleTabStateChange("2")           
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [Records])

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const getPersonSummary = (p: any) => {
        return (<BTPersonReport person={p} />)
    }

    
    const GridToolsClick = (args: ClickEventArgs) => {

        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'ECrawl.xlsx';
            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
    ] };

    const handleFormSubmit = async (formData:eCRAWL) =>{
        let res= await SetEcrawl(formData)
        if(res){
            if(res?.EcrawlPOIList && res?.EcrawlPOIList.length > 0){
                await handleECrawlPOIs(res?.EcrawlPOIList)             
            }
            setEditingRecord(null)
            handleTabStateChange('2')
            RefreshTrigger()
        }
        
    }

    const handleEditButton = async (ecrawl: any) => {
        let res = await GetEcrawl(ecrawl.ECrawlID)
        if(res && res.length > 0){
            if(res[0] && res[0].ID){
                setEditingRecord(res[0])
                handleTabStateChange('1',true)
            }
        }
        
    }

    const handleDeleteButton = async (ecrawl:any) =>{
        if(window.confirm("Are you sure you would like to delete this record?")){
            let ecrawlRec = await GetEcrawl(ecrawl?.ECrawlID)
            if(ecrawlRec && ecrawlRec?.length > 0){
                if(ecrawlRec[0] && ecrawlRec[0]?.ID){
                    ecrawlRec[0].Deleted = true
                    let res = await SetEcrawl(ecrawlRec[0])
                    if(res){
                        RefreshTrigger()
                        if(res?.EcrawlPOIList && res?.EcrawlPOIList?.length > 0){
                            await handleECrawlPOIs(res?.EcrawlPOIList)
                        }
                        else{
                            RefreshTrigger()
                        }
                    }
                }              
            }                    
        }
    }

    const handleECrawlPOIs = async (poiList:POIAttributes[]) =>{
        let alerttxt = ""
        let temp = [...poiList]
        if(poiList && poiList.length > 0){
            for(let poi of poiList){
                if(poi.ErrorMessage && poi.ErrorMessage.length > 0){
                    //remove from poi list that will be set and add to error message list to show user the poi was not set
                    let idx =temp.findIndex((item:any)=> item.CaseNo === poi.CaseNo && item.PlateNumber === poi.PlateNumber)
                    for(let err of poi.ErrorMessage){
                        alerttxt+= err + '\n'
                    }
                    if(idx !== -1){
                        temp.splice(idx, 1)
                    }
                }        
            }
          
            if(temp && temp.length > 0){
                //send POIs
                //handle error results you get back by showing the user
                //if no errors show that all pois have been set successfully
                let res = await RunSetPOIList(temp)
                if(res && res.length > 0){
                    for(let response of res){
                        if(response?.AnyData){
                            if(response?.AnyData?.ErrorMessage && response?.AnyData.ErrorMessage.length > 0){

                                alerttxt += " An error has occurred when adding/deleting a POI, Case#: " + (response?.AnyData.CaseNo ?? "") + " Plate#: " + (response?.AnyData?.PlateNumber ?? "") + "\n."
                            }
                            else{
                                alerttxt += " POI has been successfully " + (response?.AnyData?.EditStatus == "Delete" ? "deleted" : "added") + ", Case#: " + (response?.AnyData.CaseNo ?? "" ) + " Plate#: " + (response?.AnyData?.PlateNumber ?? "") + "\n."
                            }
                        }
                    }
                }                                                                      
            }  
            setOpenDialog(true)
            setDialogText(alerttxt)        
        }             
        RefreshTrigger()
    }

    const actionTemplate = (ecrawl: any): any => {
        return (
            <span>
                <ButtonGroup>                
                    <Tooltip title="Edit" placement="top" arrow>
                        <IconButton  onClick={() => {handleEditButton(ecrawl);}}><EditIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top" arrow>
                        <IconButton  onClick={() =>{handleDeleteButton(ecrawl);}}><DeleteIcon /></IconButton>
                    </Tooltip>
                </ButtonGroup>
            </span>);
    }

    const handleDialogClick = () =>{
        setOpenDialog(false)
    }


    return (
        <div className="m-5 p-5">
            {/* <Snackbar open={alertOpen} autoHideDuration={20000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: "100%" }}>
                    {alertText}
                </Alert>
            </Snackbar> */}
             <Dialog
                style={{backgroundColor:"rgba(245,245,245,0.45)", border:'1px solid black'}}
                open={openDialog}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <IconButton 
                            aria-label="close" 
                            onClick={()=>setOpenDialog(false)}  
                            sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                            }}
                        >
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle style={{marginTop:'2vh'}}>
                        <h1>POIs:</h1>
                    </DialogTitle>
                    <DialogContent>
                        {dialogText.split('.').map(str => 
                            <div className={ "text-black pb-1"}>
                                {str}
                            </div>
                        )
                        }
                    </DialogContent>
                        
                    <DialogActions className={"mr-2 mb-2"}>
                        <Button variant={"outlined"} color='inherit' onClick={()=>handleDialogClick()}>Accept</Button>
                    </DialogActions>
                        
            </Dialog>
            <Box className="" style={{ justifyContent: 'center' }}>
                <img className='main-logo' alt='Electronic Carrier Roadside Accessible Watch List' src={Banner} />
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} variant="fullWidth">
                        <Tab icon={<FormatListBulleted />} label="List View" value={'2'} />
                        <Tab icon={<PostAdd />} label={tabName} value={'1'} />
                    </TabList>
                </Box>
                <TabPanel value='2'>
                    <div className={"pl-10 pr-10 mt-5"}>
                        <h4 className={"font-bold pl-5"}>
                            {formatData?.length} Records Found
                        </h4>

                        {formatData && formatData.length > 0 && <>
                            <GridComponent
                                dataSource={formatData}
                                //dataBound={dataBound}
                                allowPaging={true}
                                pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                                allowSorting={true}
                                sortSettings={sortSettings}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{ type: 'CheckBox' }}
                                // detailTemplate={getPersonSummary}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                allowExcelExport={true}
                                allowPdfExport={true}
                                toolbar={['ExcelExport', 'Search']}
                                toolbarClick={GridToolsClick}
                                // excelExportComplete={excelExportComplete}
                                ref={g => grid = g}
                    
                                allowResizing={true}
                                rowHeight={24}
                                rowDataBound={(args : any) => {
                                    // if (args.row) {
                                    //     if (getValue('FoundJail', args.data)  === 2){
                                    //         args.row.classList.add('red');
                                    //     }
                                    // }
                                }}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective template={actionTemplate} headerText='Action' width={200} minWidth={200} maxWidth={200} customAttributes={{ class: ['e-attr'] }} />                          
                                    <ColumnDirective field='ECrawlID' width={140} minWidth={140} maxWidth={140} headerText='ECrawl ID' visible={false} isPrimaryKey={true} />                    
                                    <ColumnDirective field='GANGRELATED' headerText='Carrier Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='CaseNumber' headerText={'DOT #'} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>                             
                                    <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                    <ColumnDirective field='MName' headerText='MI' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='DOB' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                    <ColumnDirective field='DLNo' headerText='DL #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='PlateNumber' headerText='Plate #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='VINs' headerText='VIN' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>

                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search]} />
                            </GridComponent>
                        </>
                            
                        }
                    </div>
                </TabPanel>
                <TabPanel value='1'>
                    <ECrawlInput handleReportSubmit={handleFormSubmit} editForm={editingRecord} />
                </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
};

export default ECrawlSearchResults;


