import React, { useEffect, useState } from "react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";

interface VolunteerHireStatsProps {
    HandleRefreshCount: any
    RefreshCount: number
    StartDate: Date,
    EndDate: Date
}

const VolunteerHireStats: React.FC<VolunteerHireStatsProps> = ({
    HandleRefreshCount,
    RefreshCount,
    StartDate,
    EndDate
}) => {
  const [CompanyHiring, setCompanyHiring] = useState<number>(0);
  const [TotalRequest, setTotalRequest] = useState<number>(0);
  const [theICHires, setTHEICHires] = useState<number>(0);
  const [requestFilled, setRequestFilled] = useState<number>(0);
  const [requests, setRequests] = useState<number>(0);
  const [Remaining, setRemaining] = useState<number>(0);
  const [NYrequests, NYsetRequests] = useState<number>(0);
  const [NYRemaining, NYsetRemaining] = useState<number>(0);
  const [NYRemainingOther, NYsetRemainingOther] = useState<number>(0);
  const [unfilled, setUnfilled] = useState<number>(0);
  const [NYCompanyHiring, NYsetCompanyHiring] = useState<number>(0);
  const [NYtheICHires, NYsetTHEICHires] = useState<number>(0);
  const [NYrequestFilled, NYsetRequestFilled] = useState<number>(0);
  const [NYunfilled, NYsetUnfilled] = useState<number>(0);
  const [data, setData] = useState<any>([]);
  const [currentState, setCurrentState] = useState<string>("");

  useEffect(() => {
    const payload = {
      queryType: "VOLUNTEERHIRE_DAY",
      dateTypeValue: 1,
      FromDate: StartDate,
      ToDate: EndDate,
    };
      NJPortDashboardDataApi(payload).then((qRes) => {
          setCompanyHiring(0)
          setTotalRequest(0)
          setTHEICHires(0)
          setRequests(0)
          setRequestFilled(0)
          setRemaining(0)
          setUnfilled(0)
          NYsetCompanyHiring(0)
          NYsetTHEICHires(0)
          NYsetRequestFilled(0)
          NYsetUnfilled(0)
          NYsetRequests(0)
          NYsetRequestFilled(0)
          NYsetRemaining(0)
          NYsetRemainingOther(0)
      setData(qRes?.AnyTable);
    });
  }, [RefreshCount,StartDate,EndDate]);

  useEffect(() => {
    if (data) {
      data.map((item: any) => {
        if (item.StateID === "NJ") {
          if (item.CompanyHiring !== undefined) {
            setCompanyHiring(item.CompanyHiring);
          }
          if (item.TotalRequest !== undefined) {
              setTotalRequest(item.TotalRequest);
          }
          if (item.THEICHiring !== undefined) {
              setTHEICHires(item.THEICHiring);
          }
          // if (item.Requested !== undefined && item.JobID === "LS") {
          //   setRequestFilled(item.Requested);
          // }

          if (item.Requested !== undefined) {
              setRequests(item.Requested);
          }

          if (item.RequestedOther !== undefined) {
              setRequestFilled(item.RequestedOther);
          }

          if (item.Remaining !== undefined) {
              setRemaining(item.Remaining);
          } else {
            setRemaining(0);
          }

          if (item.Unfilled !== undefined) {
            setUnfilled(item.Unfilled);
          } 
        }
        else if (item.StateID === "NY") {
          if (item.CompanyHiring !== undefined) {
              NYsetCompanyHiring(item.CompanyHiring);
          }
          if (item.THEICHiring !== null) {
              NYsetTHEICHires(item.THEICHiring);
          }
          if (item.RequestsFilled !== undefined && item.JobID === "LS") {
              NYsetRequestFilled(item.RequestsFilled);
          }
          if (item.Unfilled !== undefined) {
              NYsetUnfilled(item.Unfilled);
          } 
          if (item.Requested !== undefined) {
              NYsetRequests(item.Requested);
          }
          if (item.RequestedOther !== undefined) {
              NYsetRequestFilled(item.RequestedOther);
          }
          if (item.Remaining !== undefined && item.Remaining !== undefined) {
              NYsetRemaining(item.Remaining);
          } else {
            NYsetRemaining(0);
          }          
          if (item.RemainingOther !== undefined) {
              NYsetRemainingOther(item.RemainingOther);
          }
          setCurrentState("NY"); // Set current state to NY when data for NY is found
        }
      });
    }
  }, [data]);

  if (!data || data?.length <= 0) {
    return (
      <div
        className={"text-center text-base xl:text-3xl mt-6 mb-1"}
        style={{ color: "red", fontWeight: 500 }}
      >
        No Data
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center">
    
    <h2 className="w-full font-bold text-lg text-center p-2 ">NJ - NEW JERSEY</h2>

    <div className="flex flex-col w-full">
      <div className="flex justify-between p-2 border-b border-gray-200">
        <span>Open Job Requests</span>
        <span>{TotalRequest}</span>
      </div>
      <div className="flex justify-between p-2 border-b border-gray-200">
        <span>Company List Hires</span>
        <span>{CompanyHiring}</span>
      </div>
      <div className="flex justify-between p-2 border-b border-gray-200">
        <span>Requests Filled by New Jersey Longshoremen</span>
        <span>{requests}</span>
      </div>
      <div className="flex justify-between p-2 border-b border-gray-200">
        <span>Requests filled by New York Longshoremen</span>
        <span>{requestFilled}</span>
      </div>
      <div className="flex justify-between p-2 border-b border-gray-200">
        <span>Unfilled New Jersey Requests</span>
        <span>{Remaining}</span>
      </div>
      
    </div>


    <h2 className="w-full font-bold text-lg text-center p-2">NY - NEW YORK</h2>


    <div className="flex flex-col w-full">
      <div className="flex justify-between p-2 border-b border-gray-200">
        <span>REQUESTS FILLED BY NEW YORK LONGSHOREMEN</span>
        <span>{NYrequests}</span>
      </div>
      <div className="flex justify-between p-2 border-b border-gray-200">
        <span>REQUESTS FILLED BY NEW JERSEY LONGSHOREMEN</span>
        <span>{NYrequestFilled}</span>
      </div>
      <div className="flex justify-between p-2 border-b border-gray-200">
        <span>UNFILLED NEW JERSEY REQUESTS</span>
        <span>{NYRemaining}</span>
      </div>
      <div className="flex justify-between p-2 border-b border-gray-200">
        <span>UNFILLED NEW YORK REQUESTS</span>
        <span>{NYRemainingOther}</span>
      </div>
      
    </div>
  </div>
  );
};

export default VolunteerHireStats;
