import React, { useState } from 'react';
import { SiteName } from '../../services/config.service';
import { getUser, isUserPermission } from "../../services/auth.service";
import {GetDarInfo_Query, getDarInfoResponse} from "../../interfaces/getDarInfo_interface";
import {handleSearchTime} from "../../services/formatDate.service";
import {RunDarQuery} from "../../services/getDar.service";
import {Account} from "../../interfaces/auth_interface";
import EventReportSearch from "../../components/tools/event_report/_eventReportSearch";

function EventReport() {
    let [user, setUser] = useState<Account>(getUser())

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "EVENT_REPORT",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": params.showDate,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": dropDowns.Sex ? dropDowns.Sex : [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "IncidentCities": dropDowns.IncidentCity ? dropDowns.IncidentCity : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang":  dropDowns.Gang ? dropDowns.Gang : [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": params.DL ? params.DL.split(',') : [],
            "PlateNos": params.Plate ? params.Plate.split(',') : [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "InfocopDept": dropDowns.InfoCopDept ? dropDowns.InfoCopDept : [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": dropDowns.ArrestType ? dropDowns.ArrestType : [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [],
            "SSN": params.SSN ? params.SSN?.split(',') : [],
            "PlaceOfBirth": dropDowns.PlaceOfBirth ? dropDowns.PlaceOfBirth : [],
            "AgeRangeStart": params.AgeRangeStart,
            "AgeRangeEnd": params.AgeRangeEnd,
            "FTSIDs": params.FTSID ? params.FTSID.split(',') : [],
            "ShootingPersonTypes": dropDowns.ShootingPersonType ? dropDowns.ShootingPersonType : [],
            "PhoneNumbers":  params.Phone ? params.Phone?.replaceAll('+1','')?.replaceAll('-','')?.split(',') : [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes:getDarInfoResponse = await RunDarQuery(search_query)
        console.log(qRes)
    }

    
    return (
        <div id="ToolsArrest">
            {isUserPermission('Arrest') ?
                <>
                    <EventReportSearch SubmitSearch={SubmitSearch} />
                   
                </>
                : <></>}
        </div>
    );
}

export default EventReport;