import React, { useState } from 'react'
import {
    Tooltip, Alert, AlertColor, Button, ButtonGroup, Snackbar,
} from "@mui/material";

import BTPersonReport from "../../shared/_BTPersonReport";

import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, SortSettingsModel, ExcelExportProperties, Column,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { showCrumbScore, SiteName } from '../../../services/config.service';
import BTPersonGridImage from "../../shared/_PersonGridImage";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import { getValue } from "@syncfusion/ej2-base";
import { GetStringNameForSite, SITE } from "../../../interfaces/config.interface";
import { isUserPermission } from '../../../services/auth.service';

interface SearchResultsProps {
    Persons: any[]
    hideJail?: boolean
}

const SearchResults: React.FC<SearchResultsProps> = ({ Persons, hideJail = false }) => {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    //Alerts
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("error")

    let grid: Grid | null;
    let selectedRows: any[] = [];

    const handleDataTyping = () => {
        if (Persons) {
            let tempP: any = [...Persons]
            if (hideJail) {
                tempP = tempP.filter((e: any) => e.FoundJail !== 2)
            }
            if (grid)  // added
                grid.dataSource = tempP  // added

            for (let p of tempP) {
                if (p.IssueDate) {
                    p.IssueDate = new Date(p.IssueDate)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.ExpirationDate) {
                    p.ExpirationDate = new Date(p.ExpirationDate)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [Persons, hideJail])

    const miniPersonImage = (person: any): any => {
        return (
            <BTPersonGridImage person={person} />
        )
    }
    const gridTemplate = (person: any): any => {
        return (
            <BTPersonIndicatorGrid person={person} />
        );
    }

    const getPersonSummary = (p: any) => {
        return (<BTPersonReport person={p} />)
    }

    const GridToolsClick = (args: ClickEventArgs) => {

        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Profession.xlsx';

            (grid.getColumnByField("image") as Column).visible = false;
            (grid.getColumnByField("FBI") as Column).visible = true;
            (grid.getColumnByField("SBINo") as Column).visible = true;
            (grid.getColumnByField("SSN1") as Column).visible = true;
            (grid.getColumnByField("FoundALPR") as Column).visible = true;
            (grid.getColumnByField("FoundArrest") as Column).visible = true;
            (grid.getColumnByField("FoundCADRMS") as Column).visible = true;
            (grid.getColumnByField("FoundCarTheft") as Column).visible = true;
            (grid.getColumnByField("FoundCarTheftRecent") as Column).visible = true;
            (grid.getColumnByField("FoundDomViolence") as Column).visible = true;
            (grid.getColumnByField("FoundNIBIN") as Column).visible = true;
            (grid.getColumnByField("FoundPhoto") as Column).visible = true;
            (grid.getColumnByField("FoundSexCrime") as Column).visible = true;
            (grid.getColumnByField("FoundShooting") as Column).visible = true;

            if (!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)) {
                (grid.getColumnByField("FoundCODIS") as Column).visible = true;
                (grid.getColumnByField("FoundETicket") as Column).visible = true;
                (grid.getColumnByField("FoundGunTrace") as Column).visible = true;
                (grid.getColumnByField("FoundHumanTraffic") as Column).visible = true;
                (grid.getColumnByField("FoundInfoCop") as Column).visible = true;
                (grid.getColumnByField("FoundJail") as Column).visible = true;
                (grid.getColumnByField("FoundNJGunTrace") as Column).visible = true;
                (grid.getColumnByField("FoundParkMobile") as Column).visible = true;
                (grid.getColumnByField("FoundPrisonParole") as Column).visible = true;
                (grid.getColumnByField("FoundPrisonRelease") as Column).visible = true;
                (grid.getColumnByField("FoundProfession") as Column).visible = true;
                (grid.getColumnByField("FoundProfile") as Column).visible = true;
                (grid.getColumnByField("FoundReferral") as Column).visible = true;
                (grid.getColumnByField("FoundTowing") as Column).visible = true;
                (grid.getColumnByField("FoundViolent") as Column).visible = true;
                (grid.getColumnByField("FoundNJSTAD") as Column).visible = true;
                (grid.getColumnByField("FoundNJTrace_Pos") as Column).visible = true;
                (grid.getColumnByField("FoundNJTrace_Asc") as Column).visible = true;
                (grid.getColumnByField("FoundETrace_Pur") as Column).visible = true;
                (grid.getColumnByField("FoundNJTrace_Ghg") as Column).visible = true;
                (grid.getColumnByField("FoundHomeBurglary") as Column).visible = true;
                (grid.getColumnByField("FoundHomeInvasion") as Column).visible = true;
            }
            else if (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) {
                (grid.getColumnByField("FoundCarJacking") as Column).visible = true;
                (grid.getColumnByField("FoundGunArrests") as Column).visible = true;
            }
            if (isUserPermission('Indicator_ForeignNational')) {
                (grid.getColumnByField("FoundForeignNational") as Column).visible = true;
            } 
            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("image") as Column).visible = true;
            (grid.getColumnByField("FBI") as Column).visible = false;
            (grid.getColumnByField("SBINo") as Column).visible = false;
            (grid.getColumnByField("SSN1") as Column).visible = false;
            (grid.getColumnByField("FoundALPR") as Column).visible = false;
            (grid.getColumnByField("FoundArrest") as Column).visible = false;
            (grid.getColumnByField("FoundCADRMS") as Column).visible = false;
            (grid.getColumnByField("FoundCarTheft") as Column).visible = false;
            (grid.getColumnByField("FoundCarTheftRecent") as Column).visible = false;
            (grid.getColumnByField("FoundDomViolence") as Column).visible = false;
            (grid.getColumnByField("FoundNIBIN") as Column).visible = false;
            (grid.getColumnByField("FoundPhoto") as Column).visible = false;
            (grid.getColumnByField("FoundSexCrime") as Column).visible = false;
            (grid.getColumnByField("FoundShooting") as Column).visible = false;

            if(!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)){
                (grid.getColumnByField("FoundCODIS") as Column).visible = false;
                (grid.getColumnByField("FoundETicket") as Column).visible = false;
                (grid.getColumnByField("FoundGunTrace") as Column).visible = false;
                (grid.getColumnByField("FoundHumanTraffic") as Column).visible = false;
                (grid.getColumnByField("FoundInfoCop") as Column).visible = false;
                (grid.getColumnByField("FoundJail") as Column).visible = false;
                (grid.getColumnByField("FoundNJGunTrace") as Column).visible = false;
                (grid.getColumnByField("FoundParkMobile") as Column).visible = false;
                (grid.getColumnByField("FoundPrisonParole") as Column).visible = false;
                (grid.getColumnByField("FoundPrisonRelease") as Column).visible = false;
                (grid.getColumnByField("FoundProfession") as Column).visible = false;
                (grid.getColumnByField("FoundProfile") as Column).visible = false;
                (grid.getColumnByField("FoundReferral") as Column).visible = false;
                (grid.getColumnByField("FoundTowing") as Column).visible = false;
                (grid.getColumnByField("FoundViolent") as Column).visible = false;
                (grid.getColumnByField("FoundNJSTAD") as Column).visible = false;
                (grid.getColumnByField("FoundNJTrace_Pos") as Column).visible = false;
                (grid.getColumnByField("FoundNJTrace_Asc") as Column).visible = false;
                (grid.getColumnByField("FoundETrace_Pur") as Column).visible = false;
                (grid.getColumnByField("FoundNJTrace_Ghg") as Column).visible = false;
                (grid.getColumnByField("FoundHomeBurglary") as Column).visible = false;
                (grid.getColumnByField("FoundHomeInvasion") as Column).visible = false;
            }
            else if(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL){
                (grid.getColumnByField("FoundCarJacking") as Column).visible = false;
                (grid.getColumnByField("FoundGunArrests") as Column).visible = false;
            }
            if (isUserPermission('Indicator_ForeignNational')) {
                (grid.getColumnByField("FoundForeignNational") as Column).visible = false;
            } 
        }
    }
    
    

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const expandRow = (args: any) => {
        let rows = [...selectedRows];
        if (args?.isHeaderCheckboxClicked) {
            rows = [];
            for (let idx = 0; idx < typedPersons.length; idx++) {
                let person: any = typedPersons[idx];
                if (person && person.PersonID) {
                    rows.push(person)
                }
            }
        }
        else {
            if (args?.data && args?.data?.length !== typedPersons.length) {
                var result = rows.findIndex(x => x.PersonID === args?.data.PersonID)
                if (result === -1) {
                    let person = args?.data;
                    if (person && person.PersonID) {
                        rows.push(person)
                    }
                }
                else {
                    rows.splice(result, 1)
                }
            }
        }
        selectedRows = rows
    }

    const rowDeselect = (args: any) => {
        let rows = [...selectedRows];
        if (args?.isHeaderCheckboxClicked) {
            rows = [];
        }
        else {
            if (args?.data && args?.data?.length !== typedPersons.length) {
                var result = rows.findIndex(x => x.TableID === args?.data.TableID && x.TableType === args?.data.TableType)

                if (result === -1) {
                    let person = args?.data;
                    if (person && person.PersonID) {
                        rows.push(person)
                    }
                }
                else {
                    rows.splice(result, 1)
                }
            }
        }
        selectedRows = rows;
    }

    let sortSettings: SortSettingsModel = {
        columns: [
            { field: 'FName', direction: 'Ascending' }
        ]
    };

    const toolbarOptions: object[] = [
        { text: "Excel Export", prefixIcon: "e-excelexport", tooltipText: "Excel Export", type: "Button", }, { text: "Search" }
    ]

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons.length > 0 ? <>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }}>
                    <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                        {alertText}
                    </Alert>
                </Snackbar>
                <span style={{ display: "inline-block" }} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {typedPersons?.length} Offender(s) Found</h4>
                </span>
                <GridComponent
                    dataSource={typedPersons}
                    dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    detailTemplate={getPersonSummary}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    //toolbar={['ExcelExport', 'Search']}
                    toolbar={toolbarOptions}
                    toolbarClick={GridToolsClick}
                    excelExportComplete={excelExportComplete}
                    ref={g => grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        checkboxOnly: true,
                        mode: "Row"
                    }}
                    //rowSelecting={(event: any) => { }}
                    rowSelected={(event: any) => { expandRow(event) }}
                    rowDeselected={(event: any) => { rowDeselect(event) }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    rowDataBound={(args: any) => {
                        if (args.row) {
                            if (getValue('FoundJail', args.data) === 2) {
                                args.row.classList.add('red');
                            }
                        }
                    }}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='PersonID' width={140} minWidth={140} maxWidth={140} headerText='Person ID' visible={false} isPrimaryKey={true} />
                        <ColumnDirective field='image' template={miniPersonImage} maxWidth={120} headerText='Image' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='MName' headerText='MI' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FBI' width={110} minWidth={110} maxWidth={110} headerText='FBI' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SBINo' width={110} minWidth={110} maxWidth={110} headerText={`${GetStringNameForSite(SiteName, 'SBI #')}`} customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SSN1' width={110} minWidth={110} maxWidth={110} headerText='SSN' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='ProfessionName' headerText='Profession' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        {showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText={`${GetStringNameForSite(SiteName, 'SCOR_TBL')}`} customAttributes={{ class: ['e-attr'] }} /> : <></>}
                        <ColumnDirective field='City' headerText={`${GetStringNameForSite(SiteName, 'CITY')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='State' headerText='State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective template={gridTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='STATUTE' headerText={`${GetStringNameForSite(SiteName, 'STATUTE')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='STATUTEDESCRIPTION' headerText='Statute Description' width={240} minWidth={240} maxWidth={240} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='IssueDate' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Issue Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='ExpirationDate' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Expiration Date' width={240} minWidth={240} maxWidth={240} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Status' headerText='Status' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DTOFARREST' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Date Arrested' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='ORIGINATINGORINAME' headerText='Agency Arrested' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='Dept' maxWidth={200} headerText='InfoCop Dept' customAttributes={{ class: ['e-attr'] }} />}
                        <ColumnDirective field='FoundALPR' width={110} minWidth={110} maxWidth={110} headerText='Associated ALPR' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundArrest' width={110} minWidth={110} maxWidth={110} headerText='Associated Arrest' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundCADRMS' width={110} minWidth={110} maxWidth={110} headerText={`Associated ${GetStringNameForSite(SiteName,'CADRMS')}`} customAttributes={{ class: ['e-attr'] }} visible={false} />
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundCODIS' width={110} minWidth={110} maxWidth={110} headerText='Associated CODIS' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundCarJacking' width={110} minWidth={110} maxWidth={110} headerText='Associated CarJacking' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        <ColumnDirective field='FoundCarTheft' width={110} minWidth={110} maxWidth={110} headerText='Associated Car Theft' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundCarTheftRecent' width={110} minWidth={110} maxWidth={110} headerText='Associated Car Theft Recent' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundDomViolence' width={110} minWidth={110} maxWidth={110} headerText='Associated Domestic Violence' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundETicket' width={110} minWidth={110} maxWidth={110} headerText='Associated ETicket' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundGunArrests' width={110} minWidth={110} maxWidth={110} headerText='Associated Gun Arrests' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundGunTrace' width={110} minWidth={110} maxWidth={110} headerText='Associated Gun Trace' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundHumanTraffic' width={110} minWidth={110} maxWidth={110} headerText='Associated Human Traffic' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundInfoCop' width={110} minWidth={110} maxWidth={110} headerText='Associated Info Cop' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundJail' width={110} minWidth={110} maxWidth={110} headerText='Associated Jail' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        <ColumnDirective field='FoundNIBIN' width={110} minWidth={110} maxWidth={110} headerText='Associated NIBIN' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundNJGunTrace' width={110} minWidth={110} maxWidth={110} headerText='Associated NJGunTrace' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundParkMobile' width={110} minWidth={110} maxWidth={110} headerText='Associated ParkMobile' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        <ColumnDirective field='FoundPhoto' width={110} minWidth={110} maxWidth={110} headerText='Associated Photo' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundPrisonParole' width={110} minWidth={110} maxWidth={110} headerText='Associated Prison/Parole' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundPrisonRelease' width={110} minWidth={110} maxWidth={110} headerText='Associated Prison Release' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundProfession' width={110} minWidth={110} maxWidth={110} headerText='Associated Profession' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundProfile' width={110} minWidth={110} maxWidth={110} headerText='Associated Profile' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundReferral' width={110} minWidth={110} maxWidth={110} headerText='Associated Referral' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        <ColumnDirective field='FoundSexCrime' width={110} minWidth={110} maxWidth={110} headerText='Associated Sex Crime' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundShooting' width={110} minWidth={110} maxWidth={110} headerText='Associated Shooting' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundTowing' width={110} minWidth={110} maxWidth={110} headerText='Associated Towing' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundViolent' width={110} minWidth={110} maxWidth={110} headerText='Associated Violent' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundNJSTAD' width={110} minWidth={110} maxWidth={110} headerText='Associated NJSTAD' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundNJTrace_Pos' width={110} minWidth={110} maxWidth={110} headerText='Associated NJ Gun Trace Possessor' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundNJTrace_Asc' width={110} minWidth={110} maxWidth={110} headerText='Associated NJ Gun Trace Associate' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundETrace_Pur' width={110} minWidth={110} maxWidth={110} headerText='Associated Gun Trace Purchaser' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundNJTrace_Ghg' width={110} minWidth={110} maxWidth={110} headerText='Associated NJ Gun Trace Ghost Gun' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundHomeBurglary' width={110} minWidth={110} maxWidth={110} headerText='Associated Home Burglary' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundHomeInvasion' width={110} minWidth={110} maxWidth={110} headerText='Associated Home Invasion' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {isUserPermission('Indicator_ForeignNational') && <ColumnDirective field='FoundForeignNational' width={110} minWidth={110} maxWidth={110} headerText='Associated Foreign National' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search]} />
                </GridComponent>

            </> : <></>}
        </div>
    );
};

export default SearchResults;