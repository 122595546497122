import { TSC, TSCFiles } from "../interfaces/TSC.interface";
import { AxiosResponse } from "axios";
import { PersonInfo, getDarInfoResponse } from "../interfaces/getDarInfo_interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import { FileData } from "../interfaces/GunOffender.interface";

export async function SetGunOffender(record: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/GunOffender/SetGunOffender', record)
    return (res.data)
}

export async function SetGunOffenderFile(fileObj: FileData): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/GunOffender/SetGunOffenderFile', fileObj)
    return (res.data)
}

export async function GetGunOffender(id: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.get('/api/GunOffender/GetGunOffender?IDs=' + id)
    return (res.data)
}

export async function SearchGunOffender(searchObj: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/GunOffender/SearchGunOffender', searchObj)
    return (res.data)
}