import React, {useState, useEffect} from "react";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import {convertUTCtoLocalTimeRange, formatDate} from "../../../services/formatDate.service";
import BTPersonGridImage from "../../shared/_PersonGridImage";
import {PersonInfo} from "../../../interfaces/getDarInfo_interface";


interface DetailProps {
    rpt: any | null
}

const M43SupplementReport: React.FC<DetailProps> = ({rpt}) => {
    
    const PersonImg = (person:any) => {
        let pQuery : any = {
            DOB: person?.dateOfBirth,
            FBI:  person?.fbiNumber,
            SBINo:  person?.stateIdNumber,
            FName:  person?.firstName,
            MName:  person?.middleName,
            LName:  person?.lastName

        }

        return (
            <BTPersonGridImage person={pQuery} />
        )
    }
    
    return (
        <div className="bg-white p-6 max-w-4xl mx-auto shadow-lg border border-gray-300 mt-10 ">
            <div className="bg-blue-900 text-white p-2">
                <h1 className="text-xl font-bold">CCN# {rpt.reportNumber} / SUPPLEMENT # {rpt.recordNumber}</h1>
            </div>
            <div className="grid grid-cols-3">
                <div className="border-r border-b border-gray-300 pl-2 ">
                    <p className="font-bold text-xs	">REPORT DATE / TIME</p>
                    <p> {convertUTCtoLocalTimeRange(rpt.createdDateUtc, null)}</p>
                </div>
                <div className="border-r border-b border-gray-300  pl-2">
                    <p className="font-bold text-xs	">REPORT OFFICER</p>
                    <p>{rpt?.respondingOfficer?.lastName}, {rpt?.respondingOfficer?.firstName} {rpt?.respondingOfficer?.middleName}&nbsp;</p>
                </div>
                <div className="border-b border-gray-300  pl-2">
                    <p className="font-bold text-xs">EVENT START DATE / TIME - EVENT END DATE / TIME</p>
                    <p>{convertUTCtoLocalTimeRange(rpt.eventStartUtc, rpt.eventEndUtc)}</p>
                </div>
            </div>

            {rpt?.externalSupplement?.description &&
                <div className="border-b border-gray-300">
                    <h2 className="font-bold text-base bg-blue-900 text-white p-2">DESCRIPTION </h2>
                    <p style={{overflow: 'auto'}}>
                        <pre className="text-xs"
                             style={{whiteSpace: 'pre-wrap'}}>{rpt?.externalSupplement?.description}</pre>
                    </p>
                </div>
            }

            <div className="border-b border-gray-300">
                <h2 className="font-bold text-base bg-blue-900 text-white p-2">NARRATIVE</h2>
                <p style={{overflow: 'auto'}}>
                    <pre className="text-xs" style={{whiteSpace: 'pre-wrap'}}>{rpt.narrative}</pre>
                </p>
            </div>
            

            {rpt?.reportingPersons && rpt?.reportingPersons?.map((person: any, idx: number) => (
                <div className="border-b border-gray-300">
                    <h2 className=" text-xs bg-blue-900 text-white p-2">REPORTING PERSON - {idx + 1}</h2>

                    <div className="grid grid-cols-6">
                        <div>{PersonImg(person)}&nbsp;</div>
                        <div className={"col-span-5"}>
                            <div className="grid grid-cols-2">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">NAME (LAST, FIRST
                                        MIDDLE)</p>
                                    <p>{person?.lastName}, {person?.firstName} {person?.middleName}&nbsp;</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">Date Of Birth</p>
                                    <p>{person?.dateOfBirth}&nbsp;</p>
                                </div>

                            </div>

                            <div className="grid grid-cols-5">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">PDID#</p>
                                    <p>{person?.stateIdNumber}&nbsp;</p>
                                </div>

                                <div className="border-b border-r border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">FBI#</p>
                                    <p>{person?.fbiNumber}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DL#</p>
                                    <p>{person?.licenseState} {person?.licenseNumber}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SSN#</p>
                                    <p>{person?.ssn}&nbsp;</p>
                                </div>

                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">Phone#</p>
                                    <p>{person?.phoneNumber}&nbsp;</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-4">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SEX</p>
                                    <p>{person?.sexDisplayName}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">EYE COLOR</p>
                                    <p>{person?.eyeColorDisplayName}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">RACE</p>
                                    <p>{person?.raceDisplayName}&nbsp;</p>
                                </div>
                                <div className=" border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">ETHNICITY</p>
                                    <p>{person?.ethnicityDisplayName}&nbsp;</p>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="grid grid-cols-1">
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">HOME ADDRESS</p>
                            <p>{person?.homeAddress?.streetNumber} {person?.homeAddress?.streetName}</p>
                        </div>

                    </div>
                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">CITY</p>
                            <p>{person?.homeAddress?.city}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">STATE</p>
                            <p>{person?.homeAddress?.state}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">ZIP</p>
                            <p>{person?.homeAddress?.zip}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">COUNTRY</p>
                            <p>{person?.homeAddress?.country}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                            <p>{person?.homeAddress?.crossStreet1}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                            <p>{person?.homeAddress?.crossStreet2}&nbsp;</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                            <p>{person?.homeAddress?.category}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">DISTRICT / PSA</p>
                            <p>{person?.homeAddress?.subdivision1}/{person?.homeAddress?.subdivision2}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                            <p>{person?.homeAddress?.locationPropertyType}</p>
                        </div>
                    </div>

                </div>
            ))}

            {rpt?.externalSupplement?.involvedPersons && rpt?.externalSupplement?.involvedPersons?.filter((p: any) => p?.lastName || p?.firstName)?.map((person: any, idx: number) => (
                <div className="border-b border-gray-300">
                    <h2 className=" text-xs bg-blue-900 text-white p-2">{person.subjectTypeDescription? person?.subjectTypeDescription :  person?.involvement ? person?.involvement?.replace('_IN_REPORT', '')?.replace('_', ' ') : "OTHER"}</h2>

                    <div className="grid grid-cols-6">
                        <div>{PersonImg(person)}&nbsp;</div>
                        <div className={"col-span-5"}>
                            <div className="grid grid-cols-3">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">NAME (LAST, FIRST
                                        MIDDLE)</p>
                                    <p>{person?.lastName}{person?.firstName ? ',' : ''} {person?.firstName} {person?.middleName}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DATE OF BIRTH</p>
                                    <p>{person?.dateOfBirth}&nbsp;</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DATE OF DEATH</p>
                                    <p>{formatDate(person?.dateOfDeath)}&nbsp;</p>
                                </div>

                            </div>
                            <div className="grid grid-cols-5">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">PDID#</p>
                                    <p>{person?.stateIdNumber}&nbsp;</p>
                                </div>

                                <div className="border-b border-r border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">FBI#</p>
                                    <p>{person?.fbiNumber}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DL#</p>
                                    <p>{person?.licenseState} {person?.licenseNumber}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SSN#</p>
                                    <p>{person?.ssn}&nbsp;</p>
                                </div>

                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">Phone#</p>
                                    <p>{person?.phoneNumber}&nbsp;</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-4">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">SEX</p>
                                    <p>{person?.sexDisplayName}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">EYE COLOR</p>
                                    <p>{person?.eyeColorDisplayName}&nbsp;</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">RACE</p>
                                    <p>{person?.raceDisplayName}&nbsp;</p>
                                </div>
                                <div className=" border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">ETHNICITY</p>
                                    <p>{person?.ethnicityDisplayName}&nbsp;</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    {person?.personInjuries && person?.personInjuries?.length > 0 && (
                        <div className="grid grid-cols-1">
                            <div className=" border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">INJURIES</p>
                                <p>
                                    <ul>
                                        {person?.personInjuries && person?.personInjuries?.map((injury: any) => (
                                            <li>{injury?.injuryTypeName}, {injury?.bodyPartName}, {injury?.injuryDescription} </li>
                                        ))}
                                    </ul>
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                    )}
                    {person?.identifyingMarks && person?.identifyingMarks?.length > 0 && (
                        <div className="grid grid-cols-1">
                            <div className=" border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">IDENTIFYING MARKS</p>
                                <p>
                                    <ul>
                                        {person?.identifyingMarks && person?.identifyingMarks?.map((marks: any) => (
                                            <li>{marks?.identifyingMarkType}, {marks?.identifyingMarkLocation}, {marks?.identifyingMarkDescription} </li>
                                        ))}
                                    </ul>
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                    )}

                    {person?.description && (<div className="grid grid-cols-1">
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">DESCRIPTION</p>
                            <p><pre>{person?.description}</pre>&nbsp;</p>
                        </div>
                    </div>)}


                    <div className="grid grid-cols-1">
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">HOME ADDRESS</p>
                            <p>{person?.homeAddress?.streetNumber} {person?.homeAddress?.streetName}</p>
                        </div>

                    </div>
                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">CITY</p>
                            <p>{person?.homeAddress?.city}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">STATE</p>
                            <p>{person?.homeAddress?.state}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">ZIP</p>
                            <p>{person?.homeAddress?.zip}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">COUNTRY</p>
                            <p>{person?.homeAddress?.country}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                            <p>{person?.homeAddress?.crossStreet1}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                            <p>{person?.homeAddress?.crossStreet2}&nbsp;</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                            <p>{person?.homeAddress?.category}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">DISTRICT / PSA</p>
                            <p>{person?.homeAddress?.subdivision1}/{person?.homeAddress?.subdivision2}</p>
                        </div>
                        <div className="border-b border-r border-gray-300 pl-2">
                            <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                            <p>{person?.homeAddress?.locationPropertyType}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">Resident Status</p>
                            <p>{person?.residentStatus}&nbsp;</p>
                        </div>
                    </div>

                </div>
            ))}

            {rpt?.involvedVehicles && rpt?.involvedVehicles?.map((veh: any, idx: number) => (<>
                <div className="border-b border-gray-300">
                    <h2 className="font-bold text-base bg-blue-900 text-white p-2">VEHICLE: {`${veh.make ?? ""} ${veh.model ?? ""} ${veh.yearOfManufacture ?? ""}`.toUpperCase()}</h2>


                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">ITEM CATEGORY</p>
                            <p>{veh.categoryFullName ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">MAKE</p>
                            <p>{veh.make ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">MODEL</p>
                            <p>{veh.model ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">YEAR OF MANUFACTURE</p>
                            <p>{veh.yearOfManufacture ?? ""}&nbsp;</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1">
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">DESCRIPTION
                            </p>
                            <p>{veh?.description?.toUpperCase()}</p>
                        </div>
                    </div>


                    <div className="grid grid-cols-3">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">LICENSE PLATE / REGISTRATION #</p>
                            <p>{veh?.licensePlateNumber?.toUpperCase()}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">REGISTRATION TYPE</p>
                            <p>{veh?.registrationType?.toUpperCase()}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">VIN #</p>
                            <p>{veh?.vinNumber?.toUpperCase()}</p>
                        </div>
                    </div>


                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">REGISTRATION STATE</p>
                            <p>{veh.registrationState ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">REGISTRATION YEAR</p>
                            <p>{veh.registrationYear ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INSURANCE COMPANY</p>
                            <p>{veh.insuranceProvider ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INSURANCE POLICY #</p>
                            <p>{veh.insurancePolicyNumber ?? ""}&nbsp;</p>
                        </div>
                    </div>

                    {veh?.linkedNames && veh?.linkedNames?.filter((p: any) => p?.linkedPerson?.lastName || p?.linkedPerson?.firstName)?.map((person: any, idx: number) => (
                        <div className="border-b border-gray-300">
                            <h2 className=" text-xs bg-blue-900 text-white p-2">VEHICLE OCCUPANT - {person?.nameItemAssociation ? person?.nameItemAssociation : "OTHER"}</h2>

                            <div className="grid grid-cols-6">
                                <div>{PersonImg(person?.linkedPerson)}&nbsp;</div>
                                <div className={"col-span-5"}>
                                    <div className="grid grid-cols-3">
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">NAME (LAST, FIRST
                                                MIDDLE)</p>
                                            <p>{person?.linkedPerson?.lastName?.toUpperCase()}{person?.linkedPerson?.firstName ? ',' : ''} {person?.linkedPerson?.firstName?.toUpperCase()} {person?.linkedPerson?.middleName?.toUpperCase()}&nbsp;</p>
                                        </div>
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">DATE OF BIRTH</p>
                                            <p>{person?.linkedPerson?.dateOfBirth}&nbsp;</p>
                                        </div>
                                        <div className="border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">DATE OF DEATH</p>
                                            <p>{formatDate(person?.linkedPerson?.dateOfDeath)}&nbsp;</p>
                                        </div>

                                    </div>
                                    <div className="grid grid-cols-5">
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">PDID#</p>
                                            <p>{person?.linkedPerson?.stateIdNumber}&nbsp;</p>
                                        </div>

                                        <div className="border-b border-r border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">FBI#</p>
                                            <p>{person?.linkedPerson?.fbiNumber}&nbsp;</p>
                                        </div>
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">DL#</p>
                                            <p>{person?.linkedPerson?.licenseState} {person?.linkedPerson?.licenseNumber}&nbsp;</p>
                                        </div>
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">SSN#</p>
                                            <p>{person?.linkedPerson?.ssn}&nbsp;</p>
                                        </div>

                                        <div className="border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">Phone#</p>
                                            <p>{person?.linkedPerson?.phoneNumber}&nbsp;</p>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-4">
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">SEX</p>
                                            <p>{person?.linkedPerson?.sexDisplayName?.toUpperCase()}&nbsp;</p>
                                        </div>
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">EYE COLOR</p>
                                            <p>{person?.linkedPerson?.eyeColorDisplayName?.toUpperCase()}&nbsp;</p>
                                        </div>
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">RACE</p>
                                            <p>{person?.linkedPerson?.raceDisplayName?.toUpperCase()}&nbsp;</p>
                                        </div>
                                        <div className=" border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">ETHNICITY</p>
                                            <p>{person?.linkedPerson?.ethnicityDisplayName?.toUpperCase()}&nbsp;</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {person?.linkedPerson?.personInjuries && person?.linkedPerson?.personInjuries?.length > 0 && (
                                <div className="grid grid-cols-1">
                                    <div className=" border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs	">INJURIES</p>
                                        <p>
                                            <ul>
                                                {person?.linkedPerson?.personInjuries && person?.linkedPerson?.personInjuries?.map((injury: any) => (
                                                    <li>{injury?.injuryTypeName}, {injury?.bodyPartName}, {injury?.injuryDescription} </li>
                                                ))}
                                            </ul>
                                            &nbsp;
                                        </p>
                                    </div>
                                </div>
                            )}
                            {person?.linkedPerson?.identifyingMarks && person?.linkedPerson?.identifyingMarks?.length > 0 && (
                                <div className="grid grid-cols-1">
                                    <div className=" border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs	">IDENTIFYING MARKS</p>
                                        <p>
                                            <ul>
                                                {person?.linkedPerson?.identifyingMarks && person?.linkedPerson?.identifyingMarks?.map((marks: any) => (
                                                    <li>{marks?.identifyingMarkType}, {marks?.identifyingMarkLocation}, {marks?.identifyingMarkDescription} </li>
                                                ))}
                                            </ul>
                                            &nbsp;
                                        </p>
                                    </div>
                                </div>
                            )}

                            {person?.linkedPerson?.description && (<div className="grid grid-cols-1">
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DESCRIPTION</p>
                                    <p><pre>{person?.linkedPerson?.description}</pre>&nbsp;</p>
                                </div>
                            </div>)}


                            <div className="grid grid-cols-1">
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">HOME ADDRESS</p>
                                    <p>{person?.linkedPerson?.homeAddress?.streetNumber} {person?.linkedPerson?.homeAddress?.streetName?.toUpperCase()}</p>
                                </div>

                            </div>
                            <div className="grid grid-cols-4">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">CITY</p>
                                    <p>{person?.linkedPerson?.homeAddress?.city?.toUpperCase()}</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">STATE</p>
                                    <p>{person?.linkedPerson?.homeAddress?.state?.toUpperCase()}</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">ZIP</p>
                                    <p>{person?.linkedPerson?.homeAddress?.zip?.toUpperCase()}</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">COUNTRY</p>
                                    <p>{person?.linkedPerson?.homeAddress?.country?.toUpperCase()}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                                    <p>{person?.linkedPerson?.homeAddress?.crossStreet1?.toUpperCase()}&nbsp;</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                                    <p>{person?.linkedPerson?.homeAddress?.crossStreet2?.toUpperCase()}&nbsp;</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-4">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                                    <p>{person?.linkedPerson?.homeAddress?.category}</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DISTRICT / PSA</p>
                                    <p>{person?.linkedPerson?.homeAddress?.subdivision1}/{person?.linkedPerson?.homeAddress?.subdivision2}</p>
                                </div>
                                <div className="border-b border-r border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                                    <p>{person?.linkedPerson?.homeAddress?.locationPropertyType}</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">Resident Status</p>
                                    <p>{person?.linkedPerson?.residentStatus}&nbsp;</p>
                                </div>
                            </div>

                        </div>
                    ))}


                </div>
            </>))}


        </div>
    )
}

export default M43SupplementReport;