import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";

interface VolunteerHiredProps {
    EndDate: Date
}

const VolunteerHired: React.FC<VolunteerHiredProps> = ({ EndDate }) => {
    const [data, setData] = useState<any[]>([]);
    const [option, setOption] = useState<any>({});

    useEffect(() => {
        const payload = {
            queryType: "TERMINALHIRE_WEEK",
            dateTypeValue: 1,
            endDate: EndDate,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setData(qRes?.AnyTable);
        });
    }, [EndDate]);

    useEffect(() => {
        if (data && data.length) {
            const xAxis = handleFormatXAxis();
            const seriesData = handleSeries(xAxis);

            setOption({
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                toolbox: {
                    feature: {
                        saveAsImage: { show: true },
                    },
                },
                grid: {
                    left: "1%",
                    right: "1%",
                    bottom: "1%",
                    top: "3%",
                    containLabel: true,
                },
                legend: {},
                xAxis: {
                    type: "category",
                    axisLabel: {
                        interval: 0,
                        rotate: 45,
                        fontSize: 8,
                    },
                    data: xAxis,
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                series: [
                    {
                        type: "bar",
                        color: "#1e3a8a",
                        emphasis: {
                            focus: "series",
                        },
                        label: {
                            show: true,
                            position: "inside",
                            rotate: 20,
                            fontSize: 11,
                        },
                        data: seriesData,
                    },
                ],
            });
        }
    }, [data]);

    const handleFormatXAxis = () => {
        let xAxis = [];
        let currentDate = new Date();
        // currentDate.setHours(0, 0, 0, 0);
        for (let i = 6; i >= 0; i--) {
            let date = new Date(currentDate);
            date.setDate(currentDate.getDate() - i);
            let formattedDate = date.toISOString().split("T")[0];
            xAxis.push(formattedDate);
        }
        return xAxis;
    };

    const handleSeries = (xAxis: string[]) => {
        return xAxis.map((date) => {
            let Available = 0;
            data.forEach((dataItem: any) => {

                let itemDate = new Date(dataItem?.HiringDateTime);
                itemDate.setDate(itemDate.getDate());
                const itemDateString = itemDate.toISOString().split("T")[0];

                if (itemDateString === date && dataItem?.Available !== null) {
                    Available += dataItem?.Available;
                }

            });
            return Available;
        });
    };

    return (
        <>
            {data && data.length !== 0 ? (
                <div>
                    <ReactECharts option={option} style={{ height: "200px" }} />
                </div>
            ) : (
                <div
                    className={"text-center text-base xl:text-3xl mt-6 mb-1"}
                    style={{ color: "red", fontWeight: 500 }}
                >
                    No Data
                </div>
            )}
        </>
    );
};

export default VolunteerHired;
