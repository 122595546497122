import React, {useState} from "react";
import {Account} from "../../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../../services/auth.service";
import {GetETraceDashboard, RunGetDailyStats} from "../../../services/dashboard.service";
import {getDarInfoResponse} from "../../../interfaces/getDarInfo_interface";
import {Category} from "@mui/icons-material";
import ETNYDashboardLayout from "../../../components/Dashboard/eTrace_NY/_etNYLayout";

function ETNYDashboard() {
    let [user, setUser] = useState<Account>(getUser())
    const [data, setData] = useState<any>({})
    const [personBreakdown, setPersonBreakdown] = useState<any>([])  
    const [personBreakdownLink, setPersonBreakdownLink] = useState<any>([])
    const [gunsToNIBIN, setGunsToNIBIN] = useState<any>([])
    const [top10StatesGunsAreComingFrom, setTop10StatesGunsAreComingFrom] = useState<any>([])
    const [avgTimeToCrime, setAvgTimeToCrime] = useState<any>([])
    const [avgTimeToCrimeBasedOnState, setAvgTimeToCrimeBasedOnState] = useState<any>([])
    const [top10FFLsWhereGunsWerePurchased, setTop10FFLsWhereGunsWerePurchased] = useState<any>([])
    const [countyBreakdown, setCountyBreakdown] = useState<any>([])
    const [top25CitiesBreakdown, setTop25CitiesBreakdown] = useState<any>([])
    const [heatMap, setHeatMap] = useState<any>([])
    const [recoveredGunBreakdown, setRecoveredGunBreakdown] = useState<any>([])
    const [gunRecoveryNumbersStatewide, setGunRecoveryNumbersStatewide] = useState<any>([])
    const [top10GunManufactureRecovered, setTop10ManufactureRecovered] = useState<any>([])
    const [top10GunCaliberRecovered, setTop10CaliberRecovered] = useState<any>([])
    const [top10NCICCrimeCodes, setTop10NCICCrimeCodes] = useState<any>([])
    const [shootingVictimBreakdownThisMonth, setShootingVictimBreakdownThisMonth] = useState<any>([])
    const [shootingVictimBreakdownLastMonth, setShootingVictimBreakdownLastMonth] = useState<any>([])
    const [top10PossessorWithFFLs, setTop10PossessorWithFFLs] = useState<any>([])
    const [nibinBreakdown, setNibinBreakdown] = useState<any>([])


    const [caliberBreakdown, setCaliberBreakdown] = useState<any>([])
    const [queryList, setQueryList] = useState<string[]>(
        ["personBreakdown", "personBreakdownLink" ,"gunsToNIBIN", "top10StatesGunsAreComingFrom", "avgTimeToCrime","avgTimeToCrimeBasedOnState", "top10FFLsWhereGunsWerePurchased", "countyBreakdown", "top25CitiesBreakdown",
         "HeatMap", "recoveredGunBreakdown", "gunRecoveryNumbersStatewide", "top10GunManufactureRecovered", "top10GunCaliberRecovered", "top10NCICCrimeCodes", 
         "shootingVictimBreakdownThisMonth","shootingVictimBreakdownLastMonth" ,"top10PossessorWithFFLs", "caliberBreakdown", "nibinBreakdown"])

    const GetStats = async ( params:any) => {
        for(let i of queryList){
            let temp = {...params}
            temp.Category = i
            switch(temp.Category){
                case "personBreakdown":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setPersonBreakdown(qRes ?? [])
                    })                 
                    break;
                case "personBreakdownLink":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setPersonBreakdownLink(qRes ?? [])
                    })                 
                    break;
                case "gunsToNIBIN":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setGunsToNIBIN(qRes ?? [])
                    })                 
                    break;
                case "top10StatesGunsAreComingFrom":
                    //temp.StolenType = 'ETrace'
                    //temp.State = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setTop10StatesGunsAreComingFrom(qRes ?? [])
                    })                 
                    break;
                case "avgTimeToCrime":
                    //temp.StolenType = ''
                    //temp.State = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setAvgTimeToCrime(qRes ?? [])
                    })                                
                    break;
                case "avgTimeToCrimeBasedOnState":
                    //temp.StolenType = ''
                    //temp.State = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setAvgTimeToCrimeBasedOnState(qRes ?? [])
                    })                                
                    break;
                case "top10FFLsWhereGunsWerePurchased":
                    //temp.StolenType = 'ETrace'
                    //temp.State = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setTop10FFLsWhereGunsWerePurchased(qRes ?? [])
                    })                 
                    break;
                case "top10GunManufactureRecovered":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setTop10ManufactureRecovered(qRes ?? [])
                    })                 
                    break;
                case "top10GunCaliberRecovered":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setTop10CaliberRecovered(qRes ?? [])
                    })                                
                    break;
                case "top10NCICCrimeCodes":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setTop10NCICCrimeCodes(qRes ?? [])
                    })                                
                    break;
                case "caliberBreakdown":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setCaliberBreakdown(qRes ?? [])
                    })                                
                    break;
                case "top25CitiesBreakdown":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setTop25CitiesBreakdown(qRes ?? [])
                    })                                
                    break;
                case "countyBreakdown":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setCountyBreakdown(qRes ?? [])
                    })                                
                    break;
                case "HeatMap":
                    GetETraceDashboard(temp).then((qRes) => {
                        setHeatMap(qRes ?? [])
                    })
                    break;
                // case "shootingVictimBreakdownThisMonth":
                //     temp.StolenType = ''
                //     GetETraceDashboard(temp).then((qRes) => {
                //         setShootingVictimBreakdownThisMonth(qRes ?? [])
                //     })                                
                //     break;
                // case "shootingVictimBreakdownLastMonth":
                //     temp.StolenType = ''
                //     GetETraceDashboard(temp).then((qRes) => {
                //         setShootingVictimBreakdownLastMonth(qRes ?? [])
                //     })                                
                //     break;
                case "gunRecoveryNumbersStatewide":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setGunRecoveryNumbersStatewide(qRes ?? [])
                    })                                
                    break;
                case "top10PossessorWithFFLs":
                    //temp.StolenType = 'ETrace'
                    //temp.State = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setTop10PossessorWithFFLs(qRes ?? [])
                    })                                
                    break;
                case "nibinBreakdown":
                    //temp.StolenType = ''
                    GetETraceDashboard(temp).then((qRes) => {
                        setNibinBreakdown(qRes ?? [])
                    })                                
                    break;
            }
        }
    }

    React.useEffect(() => {
        handleDashboardData()
    },[personBreakdown, personBreakdownLink, gunsToNIBIN,top10StatesGunsAreComingFrom,avgTimeToCrime, avgTimeToCrimeBasedOnState,
        top10FFLsWhereGunsWerePurchased,countyBreakdown,top25CitiesBreakdown, heatMap, recoveredGunBreakdown, gunRecoveryNumbersStatewide,
        top10GunManufactureRecovered,top10GunCaliberRecovered, top10NCICCrimeCodes, shootingVictimBreakdownThisMonth, shootingVictimBreakdownLastMonth ,top10PossessorWithFFLs,caliberBreakdown, nibinBreakdown ])
       
    
    const handleDashboardData = () => {
        let data:any = {}
        
        data.personBreakdown = personBreakdown
        data.personBreakdownLink = personBreakdownLink
        data.gunsToNIBIN = gunsToNIBIN
        data.top10StatesGunsAreComingFrom = top10StatesGunsAreComingFrom
        data.avgTimeToCrime = avgTimeToCrime
        data.avgTimeToCrimeBasedOnState = avgTimeToCrimeBasedOnState
        data.top10FFLsWhereGunsWerePurchased = top10FFLsWhereGunsWerePurchased
        data.countyBreakdown = countyBreakdown
        data.top25CitiesBreakdown = top25CitiesBreakdown
        data.HeatMap = heatMap
        data.recoveredGunBreakdown = recoveredGunBreakdown
        data.gunRecoveryNumbersStatewide = gunRecoveryNumbersStatewide
        data.top10GunManufactureRecovered = top10GunManufactureRecovered
        data.top10GunCaliberRecovered = top10GunCaliberRecovered
        data.top10NCICCrimeCodes = top10NCICCrimeCodes
        data.shootingVictimBreakdownThisMonth = shootingVictimBreakdownThisMonth
        data.shootingVictimBreakdownLastMonth = shootingVictimBreakdownLastMonth
        data.top10PossessorWithFFLs = top10PossessorWithFFLs
        data.caliberBreakdown = caliberBreakdown
        data.nibinBreakdown = nibinBreakdown
        
        setData(data)
    }

    return (
        <div>
            {isUserPermission('DashBoard_TRACE')?
                <>
                    <ETNYDashboardLayout GetStats={GetStats} data={data}/>
                </>
                :<></>}
        </div>
    );
}

export default ETNYDashboard;