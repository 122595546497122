import React, { useState } from 'react';
import { SiteName } from '../../services/config.service';
import GunOffenderSearchResults from '../../components/tools/gun_offender_entry/_searchResultsGunOffender';
import GunOffenderSearch from '../../components/tools/gun_offender_entry/_gunOffenderSearch';
import { GetDarInfo_Query, getDarInfoResponse } from '../../interfaces/getDarInfo_interface';
import { RunDarQuery } from '../../services/getDar.service';
import { Account } from '../../interfaces/auth_interface';
import { handleSearchTime } from '../../services/formatDate.service';
import { getUser } from '../../services/auth.service';
import { SearchGunOffender } from "../../services/gunoffender.service";

function GunOffender() {

    let [searchResults, setSearchResults] = useState<any[]>([])
    let [formData, setFormData] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)
    let [hideJail, setHideJail] = useState<boolean>(false)
    const [reloadTriggerCount, setReloadTriggerCount] = useState(0);

    const handleSearchSubmit = (params: any, dateType: any, andOrType: any, dropDownParams: any) => {
        SearchGunOffender({
            SearchType: andOrType,
            DateType: dateType === 'Entry Date',
            FromDate: params?.fromDate ?? null,
            ToDate: params?.toDate ?? null,
            FirstName: params?.FirstName ?? null,
            LastName: params?.LastName ?? null,
            CaseNumber: params?.CaseNo ?? null,
            GunOffenderId: params?.GunOffenderId,
            PDID: params?.PDID ?? null,
            DOB: params?.DOB,
            Address: params?.Address,
        }).then((r: any) => {
            setFormData(r);
        }).catch((e) => {
            console.log(e)
        })
    }

    const reloadData = () => {
        setReloadTriggerCount(reloadTriggerCount + 1);
    }

    return (
        <div id="ToolsArrest">
            <GunOffenderSearch SubmitSearch={handleSearchSubmit} RefreshTrigger={reloadTriggerCount} />
            <GunOffenderSearchResults Records={formData} RefreshTrigger={reloadData} />
        </div>
    );
}

export default GunOffender;
