import React, { useState } from "react";

interface FileData {
    content: string | null;
    fileName: string;
    fileEncoding: string;
    origination: string;
    size: string;
    deleted: boolean;
}

interface DropFileUploaderProps {
    originationProp?: string;
    onFileAdded: (file: FileData) => void;
}

const DropFileUploader: React.FC<DropFileUploaderProps> = ({ originationProp, onFileAdded }) => {
    const [isDragging, setIsDragging] = useState(false);

    const genId = (length: number): string => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const [fileId] = useState(genId(10));

    const allowedTypes = [".pdf", ".jpg", ".jpeg", ".png", ".doc", ".docx"];

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            Array.from(event.target.files).forEach((file) => {
                const fileExtension = file.name.split(".").pop()?.toLowerCase();
                if (!allowedTypes.includes(`.${fileExtension}`)) {
                    alert(
                        `File type not allowed: ${file.name}. Supported file types are: ${allowedTypes.join(
                            ", "
                        )}`
                    );
                    return; // Skip unsupported files
                }

                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);

                fileReader.onload = () => {
                    const newFile: FileData = {
                        content: fileReader.result?.toString().split("base64,")[1] || null,
                        fileName: file.name,
                        fileEncoding: file.type,
                        origination: originationProp && originationProp.length > 0 ? originationProp : "NO ORIGINATION GIVEN",
                        size: formatBytes(file.size),
                        deleted: false,
                    };
                    onFileAdded(newFile); // Emit the new file data
                };
            });
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileChange({ target: { files: e.dataTransfer.files } as EventTarget & HTMLInputElement, } as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const formatBytes = (bytes: number): string => {
        if (!bytes) return "0 Bytes";

        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`;
    };
    return (
        <div>
            <div
                className="p-4 bg-gray-50 border-3 border-dashed border-black-400"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <input
                    type="file"
                    multiple
                    name="file"
                    id={fileId}
                    className="hidden-input"
                    onChange={handleFileChange}
                    accept={allowedTypes.join(",")}
                />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <u style={{ textDecoration: "none" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="64px" viewBox="0 -960 960 960" width="64px" fill="#2671b5">
                                <path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520q-33 0-56.5-23.5T440-240v-206l-64 62-56-56 160-160 160 160-56 56-64-62v206h220q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h100v80H260Zm220-280Z" />
                            </svg>
                        </u>
                    </div>
                    <div>
                        <label htmlFor={fileId} className="file-label">
                            <div style={{ textAlign: "center" }}>{isDragging ? "Release to drop files here." : "Drop files here or click the button above to upload."}</div>
                            <div style={{ fontSize: "16px", textAlign: "center" }}>
                                (Accepted file types are: {allowedTypes.join(", ")})
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropFileUploader;
