import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Tab, Tooltip, Snackbar, Alert, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, Button, TextField, Typography, DialogActions, Container, Checkbox, RadioGroup, Radio, DialogContentText, Accordion, AccordionSummary, AccordionDetails, AlertColor } from '@mui/material';
import { Grid as MUIGrid } from '@mui/material';
import { DateTimePicker, LocalizationProvider, TabContext, TabList, TabPanel } from '@mui/lab';
import { PostAdd, Close, FormatListBulleted, ExpandMore, PictureAsPdf, FolderZip } from '@mui/icons-material';
import { useLocation } from "react-router-dom";
import {showCrumbScore, SiteName} from '../../../services/config.service';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search,
    ExcelExportProperties,
    Resize,
    Reorder,
    SortSettingsModel,
    CommandColumn,
    setChecked, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { GetDarInfo_Query, getDarInfoResponse } from "../../../interfaces/getDarInfo_interface";
import { base64_to_blob, base64ToBlob, convertBlobToBase64, GetDeviceToken, RunShotSpotterQuery } from "../../../services/detail.service";
import { RunDarQuery } from "../../../services/getDar.service";
import { handleSearchTime } from "../../../services/formatDate.service";
import { getUser, isUserPermission } from '../../../services/auth.service';
import { Account } from '../../../interfaces/auth_interface';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import TSCInputForm from './_tscInputForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { TSC, TSCFiles } from '../../../interfaces/TSC.interface';
import QueueIcon from '@mui/icons-material/Queue';
import { formatDT } from "../../../services/formatDate.service";
import {JAOS} from "../../../services/JAOS.service";
import BTPersonGridImage from "../../shared/_PersonGridImage";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import BTPersonReport from "../../shared/_BTPersonReport";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
import NCICIcon from "../../../assets/Images/NCIC.png";
import {getValue} from "@syncfusion/ej2-base";
import {GetGUID, GetTSCReport, SetTSCFile, SetTSCReport, GetTSCPDF, GetTSCZIP} from "../../../services/tsc.service";
import DescriptionIcon from "@mui/icons-material/Description";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
interface TSCSearchResultsProps {
    Records: TSC[]
    RefreshTrigger: any,
    hideJail?: boolean
}


const TSCSearchResults: React.FC<TSCSearchResultsProps> = ({ Records, RefreshTrigger, hideJail }) => {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    const [ncicList, setNCICList] = React.useState<any[]>([])
    let grid: Grid | null;
    let selectedRows: any[] = [];
    
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [tabValue, setTabValue] = React.useState('2');
    const [tabModLabelValue, setTabModLabelValue] = React.useState('CREATE NEW');
    const [editingRecord, setEditingRecord] = React.useState<null>(null);

    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")
    const [fileChunks, setFileChunks] = React.useState<TSCFiles[]>([{}])

    let [darTbl, setDarTbl] = React.useState<any>(null)

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    const [initialLoading, setInitialLoading] = React.useState(false);

   

    React.useEffect(() => {
        console.log("darTbl changed:", darTbl);
    }, [darTbl]); // This logs every time darTbl changes

    const checkPersonData = () => {
        let person = query.get("person");
        let data: any;
        if (person) {
            let l = new JAOS()
            data = l.objFromStack(person);
        }
        if (data) {
            setDarTbl(data); // Set state
            setTabValue('1');
            
        }
    }

    React.useEffect(() => {
        if (!initialLoading) {
            setInitialLoading(true);
            checkPersonData();
        }
    }, [initialLoading])
    

    const handleTabStateChange = (tab: any, edit: boolean = false) => {
        if (tab === '2') {
            setTabModLabelValue('CREATE NEW')
            setEditingRecord(null)
        } else if (tab === '1') {
            if (!edit) {
                setEditingRecord(null)
                setTabModLabelValue('CREATE NEW')
            } else {
                setTabModLabelValue('EDIT')
            }
        }

        setTabValue(tab)
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        handleTabStateChange(newValue)
    }

    const handleDataTyping = () => {
        if (Records) {
            let tempP: any = [...Records]
            if (hideJail) {
                tempP = tempP.filter((e: any) => e.FoundJail !== 2)
            }
            if (grid)
                grid.dataSource = tempP

            //reset NCIClist if they search again
            setNCICList([])
            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.Start_Date) {
                    p.Start_Date = new Date(p.Start_Date)
                }
                if (p.End_Date) {
                    p.End_Date = new Date(p.End_Date)
                }
            }
            setTypedPersons(tempP)
        }
    }

    const GridToolsClick = (args: ClickEventArgs) => {

        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'TSC.xlsx';

            (grid.getColumnByField("image") as Column).visible = false;
            (grid.getColumnByField("FBI") as Column).visible = true;
            (grid.getColumnByField("SBINo") as Column).visible = true;
            (grid.getColumnByField("FoundALPR") as Column).visible = true;
            (grid.getColumnByField("FoundArrest") as Column).visible = true;
            (grid.getColumnByField("FoundCADRMS") as Column).visible = true;
            (grid.getColumnByField("FoundDomViolence") as Column).visible = true;
            (grid.getColumnByField("FoundNIBIN") as Column).visible = true;
            (grid.getColumnByField("FoundPhoto") as Column).visible = true;
            (grid.getColumnByField("FoundSexCrime") as Column).visible = true;
            (grid.getColumnByField("FoundShooting") as Column).visible = true;

            if(!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)){
                (grid.getColumnByField("FoundCODIS") as Column).visible = true;
                (grid.getColumnByField("FoundETicket") as Column).visible = true;
                (grid.getColumnByField("FoundGunTrace") as Column).visible = true;
                (grid.getColumnByField("FoundHumanTraffic") as Column).visible = true;
                (grid.getColumnByField("FoundInfoCop") as Column).visible = true;
                (grid.getColumnByField("FoundJail") as Column).visible = true;
                (grid.getColumnByField("FoundNJGunTrace") as Column).visible = true;
                (grid.getColumnByField("FoundParkMobile") as Column).visible = true;
                (grid.getColumnByField("FoundPrisonParole") as Column).visible = true;
                (grid.getColumnByField("FoundPrisonRelease") as Column).visible = true;
                (grid.getColumnByField("FoundProfession") as Column).visible = true;
                (grid.getColumnByField("FoundProfile") as Column).visible = true;
                (grid.getColumnByField("FoundReferral") as Column).visible = true;
                (grid.getColumnByField("FoundTowing") as Column).visible = true;
                (grid.getColumnByField("FoundViolent") as Column).visible = true;
                (grid.getColumnByField("FoundNJSTAD") as Column).visible = true;
                (grid.getColumnByField("FoundNJTrace_Pos") as Column).visible = true;
                (grid.getColumnByField("FoundNJTrace_Asc") as Column).visible = true;
                (grid.getColumnByField("FoundETrace_Pur") as Column).visible = true;
                (grid.getColumnByField("FoundNJTrace_Ghg") as Column).visible = true;
                (grid.getColumnByField("FoundHomeBurglary") as Column).visible = true;
                (grid.getColumnByField("FoundHomeInvasion") as Column).visible = true;
            }
            else if(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL){
                (grid.getColumnByField("FoundCarJacking") as Column).visible = true;
                (grid.getColumnByField("FoundGunArrests") as Column).visible = true;
            }
            if (isUserPermission('Indicator_ForeignNational')) {
                (grid.getColumnByField("FoundForeignNational") as Column).visible = true;
            } 
            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [Records, hideJail])

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const expandRow = (args: any) => {
        //expand if a row is selected except select checkbox
        if(args?.name === 'rowSelected' && args?.target.ariaLabel != null || args?.target.alt){
            if(grid){
                const selectedrowindex= grid.getSelectedRowIndexes()
                for (let idx of selectedrowindex) {
                    grid.detailRowModule.expand(idx)
                }
            }
        }
    }

    const rowDeselect = (args: any) => {
        if(args?.name === 'rowDeselected'){
            if(grid){
                grid.detailRowModule.collapseAll()
            }
        }
    }

    const handleNCICSearch = () => {
        if (ncicList.length > 0) {
            let l = new JAOS()
            let hashID = l.objToStack(ncicList);
            window.open('/tools/ncic_multiple_occurrence?person=' + hashID, '_blank')
        }
        else {
            setAlertType("error");
            setAlertText("Please select at least one offender to run NCIC search");
            setAlertOpen(true);
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
        ] };


    const miniPersonImage = (person: any): any => {
        return (
            <BTPersonGridImage person={person} />
        )
    }
    const gridTemplate = (person: any): any => {
        return (
            <BTPersonIndicatorGrid person={person} />
        );
    }

    const selectTemplate = (person: any): any => {
        return (
            <>
                <Checkbox  onClick={() => { buildNCICList(person) }} />
            </>);
    }

    const buildNCICList = (person: any) =>{
        //Not all records have PersonID so it also checks for TableID
        let NCICListTemp = ncicList
        if (person){
            if(person.PersonID){
                var result = NCICListTemp.findIndex(x => x.PersonID === person?.PersonID)

                if (result === -1) {
                    NCICListTemp.push(person)
                }
                else {
                    NCICListTemp.splice(result, 1)
                }
            }
            else if (person.TableID){
                var result = NCICListTemp.findIndex(x => x.TableID === person?.TableID)

                if (result === -1) {
                    NCICListTemp.push(person)
                }
                else {
                    NCICListTemp.splice(result, 1)
                }
            }
        }
        setNCICList(NCICListTemp)
    }

    const getPersonSummary = (p: any) => {
        return (<BTPersonReport person={p} />)
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("image") as Column).visible = true;
            (grid.getColumnByField("FBI") as Column).visible = false;
            (grid.getColumnByField("SBINo") as Column).visible = false;
            (grid.getColumnByField("FoundALPR") as Column).visible = false;
            (grid.getColumnByField("FoundArrest") as Column).visible = false;
            (grid.getColumnByField("FoundCADRMS") as Column).visible = false;
            (grid.getColumnByField("FoundDomViolence") as Column).visible = false;
            (grid.getColumnByField("FoundNIBIN") as Column).visible = false;
            (grid.getColumnByField("FoundPhoto") as Column).visible = false;
            (grid.getColumnByField("FoundSexCrime") as Column).visible = false;
            (grid.getColumnByField("FoundShooting") as Column).visible = false;

            if(!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)){
                (grid.getColumnByField("FoundCODIS") as Column).visible = false;
                (grid.getColumnByField("FoundETicket") as Column).visible = false;
                (grid.getColumnByField("FoundGunTrace") as Column).visible = false;
                (grid.getColumnByField("FoundHumanTraffic") as Column).visible = false;
                (grid.getColumnByField("FoundInfoCop") as Column).visible = false;
                (grid.getColumnByField("FoundJail") as Column).visible = false;
                (grid.getColumnByField("FoundNJGunTrace") as Column).visible = false;
                (grid.getColumnByField("FoundParkMobile") as Column).visible = false;
                (grid.getColumnByField("FoundPrisonParole") as Column).visible = false;
                (grid.getColumnByField("FoundPrisonRelease") as Column).visible = false;
                (grid.getColumnByField("FoundProfession") as Column).visible = false;
                (grid.getColumnByField("FoundProfile") as Column).visible = false;
                (grid.getColumnByField("FoundReferral") as Column).visible = false;
                (grid.getColumnByField("FoundTowing") as Column).visible = false;
                (grid.getColumnByField("FoundViolent") as Column).visible = false;
                (grid.getColumnByField("FoundNJSTAD") as Column).visible = false;
                (grid.getColumnByField("FoundNJTrace_Pos") as Column).visible = false;
                (grid.getColumnByField("FoundNJTrace_Asc") as Column).visible = false;
                (grid.getColumnByField("FoundETrace_Pur") as Column).visible = false;
                (grid.getColumnByField("FoundNJTrace_Ghg") as Column).visible = false;
                (grid.getColumnByField("FoundHomeBurglary") as Column).visible = false;
                (grid.getColumnByField("FoundHomeInvasion") as Column).visible = false;
            }
            else if(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL){
                (grid.getColumnByField("FoundCarJacking") as Column).visible = false;
                (grid.getColumnByField("FoundGunArrests") as Column).visible = false;
            }
            if (isUserPermission('Indicator_ForeignNational')) {
                (grid.getColumnByField("FoundForeignNational") as Column).visible = false;
            } 
        }
    }
    
    
    const handleFormSubmit = (formData: any) => {
        setDarTbl(null)
        let q_person = query.get("person");
        if (q_person) {
            let l = new JAOS()
            l.delFromStack(q_person);
        }
        
        if (formData){
            //set tsc without files
            var reportWithoutFiles = JSON.parse(JSON.stringify({ ...formData }))
            if(reportWithoutFiles?.TSCPhotoList && reportWithoutFiles?.TSCPhotoList?.length > 0){
                for(let photo of reportWithoutFiles?.TSCPhotoList){
                    if(photo?.Files && photo?.Files!= null && photo?.Files?.length > 0){
                        photo.Files = []                      
                    }
                }
            }
            if(reportWithoutFiles?.InfoObjectList && reportWithoutFiles?.InfoObjectList?.length > 0){
                for(let infoObj of reportWithoutFiles?.InfoObjectList){
                    if(infoObj?.Files && infoObj?.Files != null && infoObj?.Files?.length > 0){
                        infoObj.Files = []
                    }
                }
            }
            SetTSCReport(reportWithoutFiles).then(async(res) => {
                await handleSubmitFiles(res,formData)
                setEditingRecord(null)
                handleTabStateChange('2')
                RefreshTrigger()
            })          
        }
    };

    const handleSubmitFiles = async(res:any, formData:any) =>{
        let temp = {...formData}
        const maxFileSize = 5 * 1024 * 1024 // max size 5 MB else partition file

        if(!temp?.TSCFiles){
            temp.TSCFiles = []
        }
        if (temp?.TSCPhotoList && temp?.TSCPhotoList?.length > 0) {
            for(var i of temp?.TSCPhotoList) {
                let index = res?.TSCPhotoList?.findIndex((f: any) => f.GUID === i.GUID)
                if (index >= 0) {
                    if (i.Files && !i.Files.deleted) {
                        for(let file of i.Files){   
                            if(file?.Content && file?.FileEncoding){
                                let BlobFile: any = await base64ToBlob(file.Content.includes('base64') ? file.Content.split('base64,')[1] : file.Content , file.FileEncoding)
                                file.GUID = res?.TSCPhotoList[index]?.GUID
                                file.ParentID = res?.TSCPhotoList[index]?.ID
                                file.ParentName = 'TSC_PHOTO'
                                if(BlobFile && BlobFile!= null && BlobFile?.size > maxFileSize){
                                    const totalChunks = Math.ceil(BlobFile?.size  / maxFileSize);
                                    const blobChunkList= await handleBreakIntoChunks(BlobFile, maxFileSize, totalChunks, file.FileEncoding)
                                    if(blobChunkList && blobChunkList.length > 0){
                                        // build file object to save
                                        for(let blobChunk of blobChunkList){ 
                                            let base64Content = await convertBlobToBase64(blobChunk?.currentChunk)
                                            if(typeof(base64Content) === 'string' && base64Content.includes('base64')){
                                                temp.TSCFiles.push({
                                                        ParentID: file?.ParentID,
                                                        FileName: file?.FileName,
                                                        Origination: file?.Origination,
                                                        Content:base64Content?.split('base64,')[1],
                                                        FileEncoding: file?.FileEncoding,
                                                        deleted: file?.deleted ?? false,
                                                        ParentName: file?.ParentName,
                                                        isChunk: true,
                                                        ChunkNo: blobChunk?.seqNo,
                                                        GUID: file?.GUID ?? null                                                
                                                    })
                                            }       
                                        }
                                    }                                   
                                }                              
                                else{   
                                    file.isChunk = file?.isChunk ? file?.isChunk : false    
                                    file.deleted = file?.deleted ? file?.deleted : false       
                                    temp.TSCFiles.push(file)
                                }
                            }
                            
                        }

                    }
                    // else{

                    // }
                }
            }
        }
        if(temp?.InfoObjectList && temp?.InfoObjectList?.length > 0){
            for(var i of temp?.InfoObjectList){
                let index = res?.InfoObjectList?.findIndex((f: any) => f.GUID === i.GUID)
                if (index >= 0) {
                    if (i.Files && !i.Files.deleted) {
                        for(let file of i.Files){
                            if(file?.Content && file?.FileEncoding){
                                let BlobFile: any = await base64ToBlob(file.Content.includes('base64') ? file.Content.split('base64,')[1] : file.Content , file.FileEncoding)
                                file.ParentID = res?.InfoObjectList[index]?.ID
                                file.ParentName = 'TSC_INFO'
                                //temp.TSCFiles.push(file)
                                if(BlobFile && BlobFile!= null && BlobFile?.size > maxFileSize){
                                    const totalChunks = Math.ceil(BlobFile?.size  / maxFileSize);
                                    const blobChunkList= await handleBreakIntoChunks(BlobFile, maxFileSize, totalChunks, file.FileEncoding)
                                    if(blobChunkList && blobChunkList.length > 0){
                                        // build file object to save
                                        for(let blobChunk of blobChunkList){ 
                                            let base64Content = await convertBlobToBase64(blobChunk?.currentChunk)
                                            if(typeof(base64Content) === 'string' && base64Content.includes('base64')){
                                                temp.TSCFiles.push({
                                                        ParentID: file?.ParentID,
                                                        FileName: file?.FileName,
                                                        Origination: file?.Origination,
                                                        Content:base64Content?.split('base64,')[1],
                                                        FileEncoding: file?.FileEncoding,
                                                        deleted: file?.deleted ?? false,
                                                        ParentName: file?.ParentName,
                                                        isChunk: true,
                                                        ChunkNo: blobChunk?.seqNo,
                                                        GUID: file?.GUID ?? null                                                
                                                    })
                                            }       
                                        }
                                    }       
                                }
                                else{
                                    file.isChunk = file?.isChunk ? file?.isChunk : false    
                                    file.deleted = file?.deleted ? file?.deleted : false 
                                    temp.TSCFiles.push(file)
                                }

                            }
                            
                        }

                    }
                }
            }
        }
        if (temp?.TSCFiles && temp?.TSCFiles.length > 0) {
            for (var f of temp?.TSCFiles) {
                f.ReportID = res?.ID
                f.Content = f.Content.includes('base64') ? f.Content.split('base64,')[1] : f.Content
                await SetTSCFile(f)

            }
        }     

    };

    // const mergeBase64Blobs = async (base64Array:any, contentType:any)=>{
    //     //const blobs = base64Array.map((base64:any) =>  b64toBlob(base64, contentType)); // Convert each Base64 string to a Blob
    //     let blobArr = []
    //     for(let base64 of base64Array){
    //         let res = await base64ToBlob(base64,contentType)
    //         if(res){
    //             blobArr.push(res)
    //         }
    //     }
    //     const mergedBlob = new Blob(blobArr, { type: contentType }); // Merge all Blobs
    //     return mergedBlob;

    // }

    const getBlobFile = async (file:any) =>{
        let fileBlob=null
        await base64_to_blob(file.Content, file.FileEncoding).then(r => {
            fileBlob=r
            return fileBlob
        })

        return fileBlob
    };
    
    

    const handleBreakIntoChunks = async (Blobfile:any, chunkSize:any , totalChunks:any, fileType:any) => {
        const chunks = []
        //const file = new File([Blobfile], fileName || 'chunked-image.png', { type: Blobfile.type });
        let seqNo = 0
        for(let i = 0; i < totalChunks; i++){
            seqNo+=1
            let start = i * chunkSize;
            let end = Math.min(start + chunkSize, Blobfile.size);
            let chunk = Blobfile.slice(start, end , fileType);
            chunks.push({currentChunk:chunk , seqNo:seqNo});
        }
        return chunks

    };


    React.useEffect(() => {
        handleDataTyping()
    }, [Records])



    const handleEditButton = (tsc: any) => {
        GetTSCReport(tsc.TSCID).then((res) => {
            if(res && res.length > 0){
                setEditingRecord(res[0]);
                handleTabStateChange('1', true);
            }
        }).catch((err) => {})
        
    }

    const handleDeleteButton = (tsc: any) => {
        MySwal.fire({
            text: ("Are you sure you want to delete this record?").toUpperCase(),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "DELETE RECORD" ,
        }).then((result) => {
            if (result.isConfirmed) {
                GetTSCReport(tsc.TSCID).then((res) => {
                    if(res && res.length > 0){
                        let temp = res[0];
                        temp.deleted = true;
                        SetTSCReport(temp).then((res) => {
                            RefreshTrigger()
                        }).catch((err) => {})
                    }
                }).catch((err) => {})
            }
        });
    }

    const openPdf = (tsc: any) => {
        console.log("PDF function started")
        if (tsc && tsc.TSCID) {
            console.log("Opening PDF")
            GetTSCPDF(tsc.TSCID)
        }
    }

    const openZip = (tsc: any) => {
        console.log("ZIP function started")
        if (tsc && tsc.TSCID) {
            console.log("Opening ZIP")
            GetTSCZIP(tsc.TSCID)
        }
    }
    
    const actionTemplate = (tsc: any): any => {
        return (
            <span>
                <ButtonGroup>
                    <Tooltip title="TSC PDF" placement="top" arrow>
                        <span>
                            <IconButton onClick={() => { openPdf(tsc); }}><DescriptionIcon/></IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Download Files" placement="top" arrow>
                        <span>
                            <IconButton onClick={() => { openZip(tsc); }}><FolderZip /></IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top" arrow>
                        <IconButton  onClick={() => {handleEditButton(tsc);}}><EditIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top" arrow>
                        <IconButton  onClick={() =>{handleDeleteButton(tsc);}}><DeleteIcon /></IconButton>
                    </Tooltip>
                </ButtonGroup>
            </span>);
    }
    
    return (
        <>
            <div className="m-5 p-5">
                <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                    <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%', height: '25%', fontSize: '1.2rem' }}>
                        {alertText}
                    </Alert>
                </Snackbar>
                <Box className="" style={{ justifyContent: 'center' }}>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} variant="fullWidth">
                                <Tab icon={<FormatListBulleted />} label="LIST VIEW" value={'2'} />
                                <Tab icon={<PostAdd />} label={`${tabModLabelValue} REPORT`} value={'1'} />
                            </TabList>
                        </Box>
                        <TabPanel value='2'>
                            <div className={"pl-10 pr-10 mt-5"}>
                                <h4 className={"font-bold pl-5"}>
                                    {typedPersons?.length} Records Found
                                </h4>

                                {!isLoading && typedPersons && typedPersons.length > 0 && <>
                                     <span style={{display: "inline-block"}} className='mb-5'>
                                        <h4 className={"font-bold inline mr-5 text-lg"}> {typedPersons?.length} Offender(s) Found</h4>
                                        {/* {isUserPermission('NCIC') ?
                                             <ButtonGroup size="medium" color="inherit" className='mb-2 mr-2'>
                                                 <Tooltip title="Search NCIC Warrant Deatils for Select Offender(s)"
                                                          placement="top" arrow>
                                                     <Button variant="outlined" onClick={handleNCICSearch}>
                                                         <img src={NCICIcon} className={"mr-2"} alt={""}/>NCIC Search
                                                     </Button>
                                                 </Tooltip>
                                             </ButtonGroup> : <></>
                                         }*/}
                                    </span>
                                    <GridComponent
                                        dataSource={typedPersons}
                                        dataBound={dataBound }
                                        allowPaging={true}
                                        pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                                        allowSorting={true}
                                        sortSettings={sortSettings}
                                        allowMultiSorting={true}
                                        allowFiltering={true}
                                        filterSettings={{ type: 'CheckBox' }}
                                        detailTemplate={getPersonSummary}
                                        allowTextWrap={true}
                                        textWrapSettings={{ wrapMode: 'Both' }}
                                        allowExcelExport={true}
                                        allowPdfExport={true}
                                        toolbar={['ExcelExport', 'Search']}
                                        toolbarClick={GridToolsClick}
                                        excelExportComplete={excelExportComplete}
                                        ref={g => grid = g}
                                        // selectionSettings={{
                                        //     //persistSelection: true,
                                        //     type: "Multiple",
                                        //     //checkboxOnly: true,
                                        //     mode: "Row"
                                        // }}
                                        // //rowSelecting={(event: any) => { }}
                                        // rowSelected={(event: any) => { expandRow(event) }}
                                        // rowDeselected={(event: any) => { rowDeselect(event) }}
                                        // allowSelection={true}
                                        allowResizing={true}
                                        rowHeight={24}
                                        rowDataBound={(args : any) => {
                                            if (args.row) {
                                                if (getValue('FoundJail', args.data)  === 2){
                                                    args.row.classList.add('red');
                                                }
                                            }
                                        }}
                                    >
                                        <ColumnsDirective>
                                            <ColumnDirective field='PersonID' width={140} minWidth={140} maxWidth={140} headerText='Person ID' visible={false} isPrimaryKey={true} />
                                            {/* {isUserPermission('NCIC') ? <ColumnDirective type='checkbox' width='50' customAttributes={{ class: ['e-checkbox-custom'] }} /> : <></>} */}
                                            {/*{ isUserPermission('NCIC') ? <ColumnDirective template={selectTemplate} headerText='Select' width={100} /> : <></>}*/}

                                            <ColumnDirective template={actionTemplate} headerText='Action' width={200} minWidth={200} maxWidth={200} customAttributes={{ class: ['e-attr'] }} />
                                            
                                            <ColumnDirective field='image' template={miniPersonImage} maxWidth={120} headerText='Image' customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='MName' headerText='MI' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                            <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                            <ColumnDirective field='DOB' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                            <ColumnDirective field='FBI' width={110} minWidth={110} maxWidth={110} headerText='FBI' customAttributes={{ class: ['e-attr'] }} visible={false} />
                                            <ColumnDirective field='SBINo' width={110} minWidth={110} maxWidth={110} headerText={`${GetStringNameForSite(SiteName,'SBI #')}`} customAttributes={{ class: ['e-attr'] }} visible={false} />

                                            <ColumnDirective field='SEX' headerText='Sex' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                            { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText={`${GetStringNameForSite(SiteName,'SCOR_TBL')}`} customAttributes={{ class: ['e-attr'] }}/>:<></>}
                                            <ColumnDirective field='City' headerText={`${GetStringNameForSite(SiteName,'CITY')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                            <ColumnDirective field='State' headerText='State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                            <ColumnDirective template={gridTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                            
                                            <ColumnDirective field='FoundALPR' width={110} minWidth={110} maxWidth={110} headerText='Associated ALPR' customAttributes={{ class: ['e-attr'] }} visible={false} />
                                            <ColumnDirective field='FoundArrest' width={110} minWidth={110} maxWidth={110} headerText='Associated Arrest' customAttributes={{ class: ['e-attr'] }} visible={false} />
                                            <ColumnDirective field='FoundCADRMS' width={110} minWidth={110} maxWidth={110} headerText={`Associated ${GetStringNameForSite(SiteName,'CADRMS')}`} customAttributes={{ class: ['e-attr'] }} visible={false} />
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundCODIS' width={110} minWidth={110} maxWidth={110} headerText='Associated CODIS' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundCarJacking' width={110} minWidth={110} maxWidth={110} headerText='Associated CarJacking' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            <ColumnDirective field='FoundDomViolence' width={110} minWidth={110} maxWidth={110} headerText='Associated Domestic Violence' customAttributes={{ class: ['e-attr'] }} visible={false} />
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundETicket' width={110} minWidth={110} maxWidth={110} headerText='Associated ETicket' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundGunArrests' width={110} minWidth={110} maxWidth={110} headerText='Associated Gun Arrests' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundGunTrace' width={110} minWidth={110} maxWidth={110} headerText='Associated Gun Trace' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundHumanTraffic' width={110} minWidth={110} maxWidth={110} headerText='Associated Human Traffic' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundInfoCop' width={110} minWidth={110} maxWidth={110} headerText='Associated Info Cop' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundJail' width={110} minWidth={110} maxWidth={110} headerText='Associated Jail' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            <ColumnDirective field='FoundNIBIN' width={110} minWidth={110} maxWidth={110} headerText='Associated NIBIN' customAttributes={{ class: ['e-attr'] }} visible={false} />
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundNJGunTrace' width={110} minWidth={110} maxWidth={110} headerText='Associated NJGunTrace' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundParkMobile' width={110} minWidth={110} maxWidth={110} headerText='Associated ParkMobile' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            <ColumnDirective field='FoundPhoto' width={110} minWidth={110} maxWidth={110} headerText='Associated Photo' customAttributes={{ class: ['e-attr'] }} visible={false} />
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundPrisonParole' width={110} minWidth={110} maxWidth={110} headerText='Associated Prison/Parole' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundPrisonRelease' width={110} minWidth={110} maxWidth={110} headerText='Associated Prison Release' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundProfession' width={110} minWidth={110} maxWidth={110} headerText='Associated Profession' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundProfile' width={110} minWidth={110} maxWidth={110} headerText='Associated Profile' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundReferral' width={110} minWidth={110} maxWidth={110} headerText='Associated Referral' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            <ColumnDirective field='FoundSexCrime' width={110} minWidth={110} maxWidth={110} headerText='Associated Sex Crime' customAttributes={{ class: ['e-attr'] }} visible={false} />
                                            <ColumnDirective field='FoundShooting' width={110} minWidth={110} maxWidth={110} headerText='Associated Shooting' customAttributes={{ class: ['e-attr'] }} visible={false} />
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundTowing' width={110} minWidth={110} maxWidth={110} headerText='Associated Towing' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundViolent' width={110} minWidth={110} maxWidth={110} headerText='Associated Violent' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundNJSTAD' width={110} minWidth={110} maxWidth={110} headerText='Associated NJSTAD' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundNJTrace_Pos' width={110} minWidth={110} maxWidth={110} headerText='Associated NJ Gun Trace Possessor' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundNJTrace_Asc' width={110} minWidth={110} maxWidth={110} headerText='Associated NJ Gun Trace Associate' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundETrace_Pur' width={110} minWidth={110} maxWidth={110} headerText='Associated Gun Trace Purchaser' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundNJTrace_Ghg' width={110} minWidth={110} maxWidth={110} headerText='Associated NJ Gun Trace Ghost Gun' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundHomeBurglary' width={110} minWidth={110} maxWidth={110} headerText='Associated Home Burglary' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && <ColumnDirective field='FoundHomeInvasion' width={110} minWidth={110} maxWidth={110} headerText='Associated Home Invasion' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                            {isUserPermission('Indicator_ForeignNational') && <ColumnDirective field='FoundForeignNational' width={110} minWidth={110} maxWidth={110} headerText='Associated Foreign National' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                                        </ColumnsDirective>
                                        <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search]} />
                                    </GridComponent>
                                </>
                                   
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value='1'>
                            <div className={"mt-5 mb-5 p-3 border border-dashed"} style={{maxWidth: "85vw"}}>
                                <TSCInputForm handleFormSubmit={handleFormSubmit} editForm={editingRecord} personDetails={darTbl} />
                            </div>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        </>
    )
}

export default TSCSearchResults;