import React from 'react';
import { Container, Box, Button, ButtonGroup, TextField, Grid, Typography, Autocomplete, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, AutocompleteRenderInputParams, IconButton, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip, TextareaAutosize } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { getATFStreetSuffix, getATFStateList } from '../../../services/getDropdown.service';
import DropFileUploader from '../../../components/shared/_DropFileUploader'; 
import FileHandler from '../../../components/shared/_FileHandler';
import { Today } from '@mui/icons-material';
import { ToISOLocal } from '../../../services/formatDate.service';
import {
	GunOffender
} from "../../../interfaces/GunOffender.interface";
import DCLogo from '../../../assets/Images/dc_logo.png';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getDropDownCity, getDropDownQuery } from "../../../services/getDropdown.service";
import { SiteName } from "../../../services/config.service";
import { GetDefaultStateForSite, GetStringNameForSite, IsPanelEnabled, PERSON_PANEL, SITE } from "../../../interfaces/config.interface";
import { TreeSelect } from 'primereact/treeselect';
import Divider from '@mui/material/Divider';
import { SetGunOffender, SetGunOffenderFile } from "../../../services/gunoffender.service";

interface GunOffenderProps {
	handleReportSubmit: any,
	editForm?: any,
	gunOffender?: any
}

const GunOffenderInput: React.FC<GunOffenderProps> = ({ handleReportSubmit, editForm = null, gunOffender = null }) => {
	var today = new Date();

	var todayDate = today?.toLocaleDateString()?.split('T')[0];
	const [treeSelectValue, setTreeSelectValue] = React.useState({});
	const [cityTreeSelectValue, setCityTreeSelectValue] = React.useState({});
	const [form, setForm] = React.useState<GunOffender>({
		Files: [],
		AltAddress: [
			{
				Address: ""
			}
		],
		FirstName: "",
		LastName: "",
		DOB: "",
		Race: "",
		Gender: "",
		PDID: "",
		DCDC: "",
		OffenderAddress: "",
		AddressDistrict: "",
		AddressPSA: "",
		NonDCResident: false,
		AddressState: "",
		DateAddressVisited: "",
		AddressVerified: false,
		DateRegisteredGORU: "",
		DateSignedCourtOrder: "",
		CourtCaseNo: "",
		Offense: "",
		CourtSentence: "",
		CCNNumbers: "",
		Status: 0,
		EstimatedReleaseDate: "",
		DateRequirementMetGORU: "",
		OffenseLocation: "",
		OffensePSA: "",
		OffenseState: "",
		YouthActYRA: false,
		Comments: "",
		RegisteredBy: "",
		DateReregistered: "",
		ScheduledAnnualUpdate: "",
		RegistryMonth: "",
		SupervisionStatusCSOSA: "",
		SupervisionEndDateCSOSA: "",
		UpdateDate: todayDate,
		ArrestWarrantSubmitted: false
	});
	const [offenderLevelRatings, setOffenderLevelRatings] = React.useState(["A", "B", "C"]);

	const [races, setRaces] = React.useState(["American Indian or Alaska Native", "Asian", "Black or African American", "Native Hawaiian or Other Pacific Islander", "White"])
	const [DDStatuteNew, setDDStatuteNew] = React.useState<any>([])
	const [appliedDDStatuteNew, setAppliedDDStatuteNew] = React.useState<any>(null)
	const [genders, setGenders] = React.useState(["M", "F", "X"])
	const [districts, setDistricts] = React.useState(["1", "2", "3", "4", "5", "6", "7"])
	const [statuteList, setStatuteList] = React.useState<any>([])
	const [psas, setPsas] = React.useState([
		"101", "102", "103", "104", "105", "106", "107", "108",
		"201", "202", "203", "204", "205", "206", "207", "208", "209",
		"301", "302", "303", "304", "305", "306", "307", "308", 
		"401", "402", "403", "404", "405", "406", "407", "408", "409",
		"501", "502", "503", "504", "505", "506", "507",
		"601", "602", "603", "604", "605", "606", "607", "608",
		"701", "702", "703", "704", "705", "706", "707", "708"
	])
	const [months, setMonths] = React.useState([
		"January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
	])
	const [stateList, setStateList] = React.useState<any>([]);
	const [cityList, setCityList] = React.useState<any>([]);
	const [countyList, setCountyList] = React.useState<any>([]);
	const [pageLoading, setPageLoading] = React.useState<any>(true)
	const [DDCity, setDDCity] = React.useState<any>([])
	const [DDIncidentCity, setDDIncidentCity] = React.useState<any[]>([])

	React.useEffect(() => {
		handleInitialBuild()
	}, [])

	const parseCityGlobal = (data: any): any => {
		let city = []
		let count = 1;
		for (const [key, options] of Object.entries(data)) {
			let options1: any = options;
			let cityItem: any = {
				key: "BT_UNIQUE_GROUP" + count.toString(),
				label: key,
				children: []
			}
			for (const item of options1) {
				cityItem.children.push({
					key: item.Key,
					label: item.Value,
				})
			}
			city.push(cityItem)
			count++;
		}
		return city;
	}

	const parseCity = (data: any): any => {
		let city = parseCityGlobal(data)
		setDDCity(city)
		setDDIncidentCity(city)
	}

	const clearTableRows = (table: string, field = "", value = "") => {
		let temp: any = { ...form };
		if (temp[table]) {
			if (table === "AltAddress") {
				temp[table] = [
					{
						Address: ""
					}
				]
			}
			else {
				temp[table] = [];
			}

			setForm(temp);
		}
	};

	const addDays = (date: any, days: any) => {
		let result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	};

	const getValueTemplate = (selected: any) => {
		if (selected && selected.length > 0) {
			let count = 0;
			for (const statute of selected) {
				if (!statute || !statute?.key?.includes('BT_UNIQUE_GROUP'))
					count++;
			}
			let countStr: string = count.toString();
			if (countStr === "0") {
				countStr = "None"
			}
			return (countStr + " selected");
		}
		else
			return ("")
	}

	// Function to add years to a Date object
	const addYears = (date: any, years: any) => {
		let result = new Date(date);
		result.setFullYear(result.getFullYear() + years);
		return result;
	};

	const handleDropdowns = async () => {
		getDropDownQuery('State').then(state => {
			if (state && state?.length > 0) {
				setStateList(state)
			}
		}).catch(e => { console.log(e) })

		getDropDownCity(GetDefaultStateForSite(SiteName)).then(city => {
			let tempList = city?.filter(x => x.State === GetDefaultStateForSite(SiteName))
			const ids = tempList.map(({ City }) => City);
			const filtered = tempList.filter(({ City }, index) =>
				!ids.includes(City, index + 1));
			setCityList(filtered)
		}).catch(e => { console.log(e) })

		getDropDownQuery('ResidentCounty').then(counties => {
			if (counties && counties?.length > 0) {
				setCountyList(counties)
			}
		}).catch(e => { console.log(e) })

		getDropDownQuery('Statute').then((res => {
			parseStatute(res)
		}))

		getDropDownQuery('City').then((res) => { parseCity(res) })
	}

	const parseStatute = (data: any): any => {
		let statutes: any[] = [];
		let statutesNew = [];
		let count = 1;
		for (const [key, options] of Object.entries(data)) {
			let options1: any = options;
			let statusItem: any = {
				key: "BT_UNIQUE_GROUP" + count.toString(),
				label: key,
				children: []
			}
			for (const item of options1) {
				/*statutes.push({
					key: item.Key + ' ' + item.Value,
					value: item.Key,
					group: key
				})*/
				statusItem.children.push({
					key: item.Key,
					label: item.Key + ' ' + item.Value,
				})
			}
			statutesNew.push(statusItem)
			count++;
		}
		setDDStatuteNew(statutesNew)
	}


	const handleInitialBuild = async () => {
		await handleDropdowns()
		
		setPageLoading(false)
	}

	const handleSubmit = async () => {
		console.log("Current record: ", form);
		var noFilesReport = JSON.parse(JSON.stringify({ ...form }))
		var filesReport = JSON.parse(JSON.stringify({ ...form }))
		console.log(filesReport)
		if (noFilesReport?.Files && noFilesReport?.Files?.length > 0) {
			noFilesReport.Files = []
		}
		
		console.log("Payload to SetGunOffender: ", noFilesReport);

		SetGunOffender(noFilesReport).then(async (res) => {
			let tempWithFiles = await handleSubmitFiles(filesReport, res.ID)

			handleReportSubmit(res)
		})
	}

	const handleSubmitFiles = async (data: any, recordId: any) => {
		let temp = { ...data }
		if (!temp?.Files)
			temp.Files = []

		if (temp?.Files && temp?.Files.length > 0) {
			for (var f of temp?.Files) {
				f.RecordID = recordId
				let res = await SetGunOffenderFile(f)
				f.ID = res.ID
			}
		}
		console.log(temp)
		return temp;
	}

	const deleteTableRow = (table: string, item: any) => {
		var temp: any = { ...form }
		if (table?.length > 0 && item) {
			if (temp[table]) {
				let index = temp[table]?.findIndex((f: any) => f === item)
				if ((index as any) >= 0) {
					if (temp[table][index]?.ID && (temp[table][index].ID as any) > 0) {
						temp[table][index].Deleted = true
					} else {
						temp[table]?.splice(index, 1)
					}
					setForm(temp)
				}
			}
		}
	};

	const addTableRow = (table: string, field: string = "", value: string = "") => {
		var temp: any = { ...form }
		if (!Array.isArray(temp[table])) {
			temp[table] = [];
		}

		if (field && value) {
			let newRow = { [field]: value };
			temp[table].push(newRow);
		} else {
			temp[table].push({});

		}
		setForm(temp)
	};

	const handleFile = (file: any) => {
		console.log(file)

		let temp = { ...form }

		if (temp?.Files != null && temp?.Files) {
			temp?.Files?.push(file)
		}
		setForm(temp)
	}

	const handleFileRemove = (file: any) => {
		console.log(file)

		let temp = { ...form }

		if (temp?.Files && temp?.Files?.length > 0) {
			let findIndex = temp?.Files.findIndex((f: any) => f === file);
			temp?.Files?.splice(findIndex, 1)
		}
		setForm(temp)
	}


    const handleInputChange = (e: { target: { name: string; value: any; }; }, table: string = "", item: any = null) => {

        const { name, value } = e.target;
		let temp: any = { ...form }

		const processedValue = value === "true" ? true : value === "false" ? false : value;

		if (table?.length > 0 && item) { 
			let index = temp[table]?.findIndex((f: any) => f === item)
            if (index >= 0) {
				temp[table][index][name] = (processedValue instanceof Date ? ToISOLocal(processedValue) : processedValue)
            }
		}

		else {
			temp[name] = (processedValue instanceof Date ? ToISOLocal(processedValue) : processedValue)
		}

		if (name === "OffenderLevelRating") {
			if (value === "C") {
				temp.HomeVisitDueDate = [
					{
						Date: ""
					}
				]
			}
			else if (value === "B") {
				temp.HomeVisitDueDate = [
					{
						Date: ""
					},
					{
						Date: ""
					}
				]
			}
			else if (value === "A") {
				temp.HomeVisitDueDate = [
					{
						Date: ""
					},
					{
						Date: ""
					},
					{
						Date: ""
					},
					{
						Date: ""
					}
				]
			}
		}
		setForm(temp)
		console.log(temp)
    };

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<div>
			<Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
				<Typography variant="h4" gutterBottom>
					Gun Offender Entry
				</Typography>
				<Typography variant="h5" sx={{ fontStyle: 'italic' }} gutterBottom>
					Metropolitan Police Department
				</Typography>
				<img style={{ height: "10%", width: "10%" }} src={DCLogo} alt="DC Homeland Security" />
			</Box>
			<div className="grid grid-cols-2 gap-4 w-3/5 mx-auto mt-4 h-1/2 border-2 border-black">
				<div className="pr-4 pl-4 pt-2 pb-2 col-span-1 row-span-1">
					<TextField
						label="ID"
						name="ID"
						value={form?.ID ?? "Will be assigned when saved"}
						multiline
						style={{ marginTop: '10px' }}
						fullWidth
						disabled
						sx={{
							marginTop: '10px',
							borderRadius: '8px', // Adjust as needed
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px', // Ensures the input field is rounded
							},
						}}
					/>
				</div>
				<div className="pr-4 pl-4 pt-2 pb-2 col-span-1 row-span-1">
					<TextField
						label="Gun Offender ID"
						name="GunOffenderId"
						value={form?.GunOffenderId ?? ""}
						multiline
						style={{ marginTop: '10px' }}
						fullWidth
						onChange={handleInputChange}
						sx={{
							marginTop: '10px',
							borderRadius: '8px', // Adjust as needed
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px', // Ensures the input field is rounded
							},
						}}
					/>
				</div>
				<div className="col-span-2 row-span-2"></div>
				<div className="col-span-2 grid grid-cols-3 pr-4 pl-4 gap-4">
					<TextField
						label="Offender Level Score"
						name="OffenderLevelScore"
						value={form?.OffenderLevelScore ?? ""}
						multiline
						style={{ marginTop: '10px' }}
						fullWidth
						onChange={handleInputChange}
						sx={{
							marginTop: '10px',
							borderRadius: '8px', // Adjust as needed
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px', // Ensures the input field is rounded
							},
						}}
					/>
					<Autocomplete
						id="free-solo-demo"
						autoComplete={true}
						options={offenderLevelRatings}
						value={form?.OffenderLevelRating ?? ""}
						onChange={(event, newValue) => handleInputChange({ target: { name: "OffenderLevelRating", value: newValue as string } })}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Offender Level Rating"
								variant="outlined"
								fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>
						)}
					/>
					<TextField
						label="GUNSTAT"
						name="GunStat"
						value={form?.GunStat ?? ""}
						multiline
						style={{ marginTop: '10px' }}
						fullWidth
						onChange={handleInputChange}
						sx={{
							marginTop: '10px',
							borderRadius: '8px', // Adjust as needed
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px', // Ensures the input field is rounded
							},
						}}
					/>
				</div>
				<div className="col-span-1 row-span-1 pr-4 pl-4">
					<TextField
						label="Last Name"
						name="LastName"
						value={form?.LastName ?? ""}
						multiline
						style={{ marginTop: '10px' }}
						fullWidth
						onChange={handleInputChange}
						sx={{
							marginTop: '10px',
							borderRadius: '8px', // Adjust as needed
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px', // Ensures the input field is rounded
							},
						}}
					/>
				</div>
				<div className="col-span-1 row-span-1 pr-4">
					<TextField
						label="First Name"
						name="FirstName"
						value={form?.FirstName ?? ""}
						multiline
						style={{ marginTop: '10px' }}
						fullWidth
						onChange={handleInputChange}
						sx={{
							marginTop: '10px',
							borderRadius: '8px', // Adjust as needed
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px', // Ensures the input field is rounded
							},
						}}
					/>
				</div>
				<div className="col-span-2 grid grid-cols-3 gap-4 pr-4 pl-4">
					<DatePicker
						label="Date of Birth"
						value={form?.DOB ?? ""}
						onChange={(date) => handleInputChange({ target: { name: "DOB", value: (date ?? "") } })}
							renderInput={(params) => <TextField {...params} error={form?.DOB && form?.DOB?.trim()?.length > 1 ? Date.parse(form?.DOB) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
					/>
					<Autocomplete
						id="free-solo-demo"
						autoComplete={true}
						options={races}
						value={form?.Race ?? ""}
						onChange={(event, newValue) => handleInputChange({ target: { name: "Race", value: newValue as string } })}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Race"
								variant="outlined"
								fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>
						)}
					/>
					<Autocomplete
						id="free-solo-demo"
						autoComplete={true}
						options={genders}
						value={form?.Gender ?? ""}
						onChange={(event, newValue) => handleInputChange({ target: { name: "Gender", value: newValue as string } })}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Gender"
								variant="outlined"
								fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>
						)}
					/>
				</div>
				<div className="col-span-1 row-span-1 pl-4 pr-4">
					<TextField
						label="PDID"
						name="PDID"
						value={form?.PDID ?? ""}
						multiline
						style={{ marginTop: '10px' }}
						fullWidth
						onChange={handleInputChange}
						sx={{
							marginTop: '10px',
							borderRadius: '8px', // Adjust as needed
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px', // Ensures the input field is rounded
							},
						}}
					/>
				</div>
				<div className="col-span-1 row-span-1 pr-4">
					<TextField
						label="DCDC"
						name="DCDC"
						value={form?.DCDC ?? ""}
						multiline
						style={{ marginTop: '10px' }}
						fullWidth
						onChange={handleInputChange}
						sx={{
							marginTop: '10px',
							borderRadius: '8px', // Adjust as needed
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px', // Ensures the input field is rounded
							},
						}}
					/>
				</div>
				<div className="col-span-2 grid grid-cols-3 gap-4 pl-4 pr-4">
					<TextField
						label="Offender Address"
						name="OffenderAddress"
						value={form?.OffenderAddress ?? ""}
						multiline
						style={{ marginTop: '10px' }}
						fullWidth
						onChange={handleInputChange}
						sx={{
							marginTop: '10px',
							borderRadius: '8px', // Adjust as needed
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px', // Ensures the input field is rounded
							},
						}}
					/>
					<Autocomplete
						id="free-solo-demo"
						autoComplete={true}
						options={districts}
						value={form?.AddressDistrict ?? ""}
						onChange={(event, newValue) => handleInputChange({ target: { name: "AddressDistrict", value: newValue as string } })}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Address District"
								variant="outlined"
								fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>
						)}
					/>
					<Autocomplete
						id="free-solo-demo"
						autoComplete={true}
						options={psas}
						groupBy={(option) => `District ${option[0]}`}
						value={form?.AddressPSA ?? ""}
						onChange={(event, newValue) => handleInputChange({ target: { name: "AddressPSA", value: newValue as string } })}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Address PSA"
								variant="outlined"
								fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>
						)}
					/>
				</div>
				<div className="col-span-1 row-span-1 pl-4 pr-4 flex items-center justify-center">
					<FormControl component="fieldset">
						<FormLabel component="legend" className="text-center">Non-DC Resident
						</FormLabel>
						<div className="mt-2 border border-gray-300 rounded-lg pl-4">
							<RadioGroup row className="mt-2 gap-x-16" aria-label="RFIPrevConducted"
								name="NonDCResident"
								value={form?.NonDCResident}
								onChange={handleInputChange}
								defaultValue={false}>
								<FormControlLabel value={true} control={<Radio />} label="Yes" />
								<FormControlLabel value={false} control={<Radio />} label="No" />
							</RadioGroup>
						</div>
					</FormControl>
				</div>
				<div className="col-span-1 row-span-1 pl-4 pr-4 pt-4">
					<Autocomplete
							id="free-solo-demo"
							autoComplete={true}
							options={stateList.map((option: any) => option.Key)}
							value={form?.AddressState ?? ""}
							onChange={(event, newValue) => handleInputChange({ target: { name: "AddressState", value: newValue as string } })}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Address State"
									variant="outlined"
									fullWidth
									sx={{
										marginTop: '10px',
										borderRadius: '8px', // Adjust as needed
										'& .MuiOutlinedInput-root': {
											borderRadius: '8px', // Ensures the input field is rounded
										},
									}}
								/>
							)}
					/>
					</div>
					<div className="col-span-2">
						<Divider />
					</div>
				<div className="col-span-2 text-center">
					<Typography variant="h5">Alternative Addresses</Typography>
				</div>
				<div className="col-span-2 p-4 gap-6 flex items-center justify-center">
					<Button onClick={() => addTableRow("AltAddress", "Origination", "Alt Address")} color={"success"} variant="contained">
						+ Add Alternative Address
					</Button>
					<Button onClick={() => clearTableRows("AltAddress")} color={"inherit"} variant="contained">
						X Clear All Addresses
					</Button>
				</div>
					{form?.AltAddress?.filter((f: any) => !f.deleted).map((address: any, indx) => {
						return (
							<div className="col-span-2 p-4 gap-4 flex items-center justify-center">
								<TextField
									label="Address"
									inputProps={{ style: { textAlign: 'center' } }}
									name="Address"
									value={address?.Address ?? ''}
									onChange={(e) => handleInputChange(e, "AltAddress", address)}
									sx={{
										marginTop: '10px',
										width: '50%',
										borderRadius: '8px',
										'& .MuiOutlinedInput-root': {
											borderRadius: '8px',
										},
									}}
								/>
								{indx === 0 ? (
									<div style={{ width: '40px' }} /> 
								) : (
									<IconButton onClick={() => deleteTableRow("AltAddress", address)} color="error">
										<Tooltip title="Delete Address">
											<DeleteIcon />
										</Tooltip>
									</IconButton>
								)}
							</div>
						);
					})}

					<div className="col-span-2"></div>
					<div className="col-span-1 row-span-1 pl-4 pr-4 mt-6">
						<DatePicker
							label="Date Address Visited"
							value={form?.DateAddressVisited ?? ""}
							onChange={(date) => handleInputChange({ target: { name: "DateAddressVisited", value: (date ?? "") } })}
							renderInput={(params) => <TextField {...params} error={form?.DateAddressVisited && form?.DateAddressVisited?.trim()?.length > 1 ? Date.parse(form?.DateAddressVisited) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
						/>
					</div>
					<div className="col-span-1 row-span-1 pl-4 pr-4 flex items-center justify-center">
						<FormControl component="fieldset">
							<FormLabel component="legend" className="text-center">Address Verified
							</FormLabel>
							<div className="mt-2 border border-gray-300 rounded-lg pl-4">
								<RadioGroup row className="gap-x-16" aria-label="RFIPrevConducted"
									name="AddressVerified"
									value={form?.AddressVerified}
									onChange={handleInputChange}
									defaultValue={false}>
									<FormControlLabel value={true} control={<Radio />} label="Yes" />
									<FormControlLabel value={false} control={<Radio />} label="No" />
								</RadioGroup>
							</div>
						</FormControl>
					</div>
					<div className="col-span-2 pr-4 pl-4 gap-4">
						<DatePicker
							label="Date Registered GORU"
							value={form?.DateRegisteredGORU ?? ""}
							onChange={(date) => handleInputChange({ target: { name: "DateRegisteredGORU", value: (date ?? "") } })}
							renderInput={(params) => <TextField {...params} error={form?.DateRegisteredGORU && form?.DateRegisteredGORU?.trim()?.length > 1 ? Date.parse(form?.DateRegisteredGORU) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
						/>
					</div>
					<div className="col-span-2">
						<Divider />
					</div>
					<div className="col-span-2 row-span-2 text-center pt-4">
						<Typography variant="h5">Home Visits</Typography>
					</div>
					{form?.HomeVisitDueDate?.filter((visit: any) => !visit.deleted).map((visit: any, indx: any) => {
						return (
							<>
								<div className="col-span-2 row-span-2 pl-6 pr-6 mb-2">
									<DatePicker
										label={`Home Visit ${indx + 1} Due Date ${form.OffenderLevelRating === 'A' && indx === 0 ? '(First visit must be within 30 days)' : ''}`}
										minDate={today}
										maxDate={form?.OffenderLevelRating === 'A' && indx === 0 ? addDays(today, 30) : addYears(today, 1)}
										value={visit?.Date ?? ""}
										onChange={(date) => handleInputChange({ target: { name: "Date", value: (date ?? "") } }, "HomeVisitDueDate", visit)}
										renderInput={(params) => <TextField {...params} error={visit?.Date && visit?.Date?.trim()?.length > 1 ? Date.parse(visit?.Date) ? false : true : false} fullWidth
											sx={{
												marginTop: '10px',
												borderRadius: '8px', // Adjust as needed
												'& .MuiOutlinedInput-root': {
													borderRadius: '8px', // Ensures the input field is rounded
												},
											}}
										/>}
										disabled={indx > 0 ? form?.HomeVisitDueDate?.filter((visit: any) => !visit.deleted)[indx - 1].Date === "" : false }
									/>
								</div>
							</>
						)

					})}

					{(form?.HomeVisitDueDate?.length === 0 || !form?.HomeVisitDueDate) && (
						<>
							<div className="col-span-2 row-span-2">
								<Typography variant="body1" sx={{ color: 'red', textAlign: 'center' }}>Choose an Offender Level Rating before scheduling home visit dates.</Typography>
							</div>
						</>
					)}
					<div className="col-span-1 row-span-1 p-4 gap-4">
						<DatePicker
							label="Date Signed Court Order"
							value={form?.DateSignedCourtOrder ?? ""}
							onChange={(date) => handleInputChange({ target: { name: "DateSignedCourtOrder", value: (date ?? "") } })}
							renderInput={(params) => <TextField {...params} error={form?.DateSignedCourtOrder && form?.DateSignedCourtOrder?.trim()?.length > 1 ? Date.parse(form?.DateSignedCourtOrder) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
						/>
					</div>
					<div className="col-span-1 row-span-1 p-4 gap-4">
						<TextField
							label="Court Case No."
							name="CourtCaseNo"
							value={form?.CourtCaseNo ?? ""}
							multiline
							style={{ marginTop: '10px' }}
							fullWidth
							onChange={handleInputChange}
							sx={{
								marginTop: '10px',
								borderRadius: '8px', // Adjust as needed
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px', // Ensures the input field is rounded
								},
							}}
						/>
					</div>
					<div className="col-span-2 grid grid-cols-3 gap-4 p-4">
						<FormControl className="p-float-label" sx={{
							minWidth: '100%',
							maxWidth: '100%',
							marginTop: '10px',
							borderRadius: '8px', // Adjust as needed
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px', // Ensures the input field is rounded
							},
						}} >
							<TreeSelect value={treeSelectValue} options={DDStatuteNew} filter dropdownIcon={"pi none"}
								resetFilterOnHide={true}
								onChange={(e) => {
									setTreeSelectValue(e.value || {});
									// Extract selected keys and filter out "BT_UNIQUE_GROUP*" keys
									// Extract selected keys and filter out "BT_UNIQUE_GROUP*" keys
									const selectedNames = Object.keys(e.value || {})
										.filter((key) => !key.includes("BT_UNIQUE_GROUP")) // Exclude unwanted keys
										.map((key) => {
											// Find the corresponding name (label) for each selected key
											const findName: any = (options: any) => {
												for (const option of options) {
													if (option.key === key) {
														return option.label.split(" ").slice(1).join(" "); // Extract the name after the key
													}
													if (option.children) {
														const childResult = findName(option.children);
														if (childResult) return childResult;
													}
												}
												return null;
											};
											return findName(DDStatuteNew);
										})
										.filter(Boolean) // Remove null values
										.join(", "); // Convert to a single string


									handleInputChange(
										{ target: { name: "Offense", value: selectedNames } } // Ensure it matches expected input format
									);
								}}
								selectionMode="checkbox" showClear
								placeholder={`${GetStringNameForSite(SiteName, 'Statutes')}`}
								inputId={"dd-statute"}
								style={{
									border: "1px solid #b9b9bb",
									height: "3.45rem",
									textAlign: "start",
									borderRadius: "8px", // Make the component rounded
								}}
								valueTemplate={(option: any) => getValueTemplate(option)}
							></TreeSelect>
							<label className={"bg-gray-100"} htmlFor="dd-statute">{`${GetStringNameForSite(SiteName, 'Statutes')}`}</label>
						</FormControl>
						<TextField
							label="Court Sentence"
							name="CourtSentence"
							value={form?.CourtSentence ?? ""}
							multiline
							style={{ marginTop: '10px' }}
							fullWidth
							onChange={handleInputChange}
							sx={{
								marginTop: '10px',
								borderRadius: '8px', // Adjust as needed
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px', // Ensures the input field is rounded
								},
							}}
						/>
						<TextField
							label="CCN Numbers"
							name="CCNNumbers"
							value={form?.CCNNumbers ?? ""}
							multiline
							style={{ marginTop: '10px' }}
							fullWidth
							onChange={handleInputChange}
							sx={{
								marginTop: '10px',
								borderRadius: '8px', // Adjust as needed
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px', // Ensures the input field is rounded
								},
							}}
						/>
					</div>
					<div className="col-span-2 grid grid-cols-3 gap-4 p-4">
						<TextField
							label="Status"
							name="Status"
							value={form?.Status ?? ""}
							multiline
							style={{ marginTop: '10px' }}
							fullWidth
							onChange={handleInputChange}
							sx={{
								marginTop: '10px',
								borderRadius: '8px', // Adjust as needed
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px', // Ensures the input field is rounded
								},
							}}
						/>
						<DatePicker
							label="Estimated Release Date"
							value={form?.EstimatedReleaseDate ?? ""}
							onChange={(date) => handleInputChange({ target: { name: "EstimatedReleaseDate", value: (date ?? "") } })}
							renderInput={(params) => <TextField {...params} error={form?.EstimatedReleaseDate && form?.EstimatedReleaseDate?.trim()?.length > 1 ? Date.parse(form?.EstimatedReleaseDate) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
						/>
						<DatePicker
							label="Date Requirement Met GORU"
							value={form?.DateRequirementMetGORU ?? ""}
							onChange={(date) => handleInputChange({ target: { name: "DateRequirementMetGORU", value: (date ?? "") } })}
							renderInput={(params) => <TextField {...params} error={form?.DateRequirementMetGORU && form?.DateRequirementMetGORU?.trim()?.length > 1 ? Date.parse(form?.DateRequirementMetGORU) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
						/>
					</div>
					<div className="col-span-2 grid grid-cols-3 gap-4 p-4">
						<TextField
							label="Offense Location"
							name="OffenseLocation"
							value={form?.OffenseLocation ?? ""}
							multiline
							style={{ marginTop: '10px' }}
							fullWidth
							onChange={handleInputChange}
							sx={{
								marginTop: '10px',
								borderRadius: '8px', // Adjust as needed
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px', // Ensures the input field is rounded
								},
							}}
						/>
						<Autocomplete
							id="free-solo-demo"
							autoComplete={true}
							options={psas}
							groupBy={(option) => `District ${option[0]}`}
							value={form?.OffensePSA ?? ""}
							onChange={(event, newValue) => handleInputChange({ target: { name: "OffensePSA", value: newValue as string } })}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Offense PSA"
									variant="outlined"
									fullWidth
									sx={{
										marginTop: '10px',
										borderRadius: '8px', // Adjust as needed
										'& .MuiOutlinedInput-root': {
											borderRadius: '8px', // Ensures the input field is rounded
										},
									}}
								/>
							)}
						/>
						<Autocomplete
							id="free-solo-demo"
							autoComplete={true}
							options={stateList.map((option: any) => option.Key)}
							value={form?.OffenseState ?? ""}
							onChange={(event, newValue) => handleInputChange({ target: { name: "OffenseState", value: newValue as string } })}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Offense State"
									variant="outlined"
									fullWidth
									sx={{
										marginTop: '10px',
										borderRadius: '8px', // Adjust as needed
										'& .MuiOutlinedInput-root': {
											borderRadius: '8px', // Ensures the input field is rounded
										},
									}}
								/>
							)}
						/>
					</div>
					<div className="col-span-2 p-4 flex items-center justify-center">
						<FormControl component="fieldset">
							<FormLabel component="legend" className="text-center">Youth Act YRA
							</FormLabel>
							<div className="mt-2 border border-gray-300 rounded-lg pl-4">
								<RadioGroup row className="mt-2 gap-x-16" aria-label="YouthActYRA"
									name="YouthActYRA"
									value={form?.YouthActYRA}
									onChange={handleInputChange}
									defaultValue={false}>
									<FormControlLabel value={true} control={<Radio />} label="Yes" />
									<FormControlLabel value={false} control={<Radio />} label="No" />
								</RadioGroup>
							</div>
						</FormControl>
					</div>
					<div className="col-span-2">
						<Divider />
					</div>
					<div className="col-span-2 row-span-2 p-4 text-center">
						<Typography variant="h5">Comments</Typography>
					</div>
					<div className="col-span-2 row-span-2 p-4">
						<FormControl fullWidth>
							<InputLabel shrink htmlFor="Comments">Comments</InputLabel>
							<TextareaAutosize
								id="Comments"
								name="Comments"
								value={form?.Comments ?? ""}
								onChange={handleInputChange}
								style={{
									width: '100%', marginTop: '10px',
									border: '1px solid rgba(0, 0, 0, 0.23)',
									borderRadius: '4px',
									padding: '16.5px 14px',
									fontSize: '16px',
									lineHeight: '1.4375em',
									backgroundColor: 'inherit',
									resize: 'vertical'
								}}
								minRows={10}
							/>
						</FormControl>
					</div>
					<div className="col-span-2">
						<Divider />
					</div>
					<div className="col-span-1 row-span-1 p-4 gap-4">
						<DatePicker
							label="Date Reregistered"
							value={form?.DateReregistered ?? ""}
							onChange={(date) => handleInputChange({ target: { name: "DateReregistered", value: (date ?? "") } })}
							renderInput={(params) => <TextField {...params} error={form?.DateReregistered && form?.DateReregistered?.trim()?.length > 1 ? Date.parse(form?.DateReregistered) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
						/>
					</div>
					<div className="col-span-1 row-span-1 p-4 gap-4">
						<DatePicker
							label="Scheduled Annual Update"
							value={form?.ScheduledAnnualUpdate ?? ""}
							onChange={(date) => handleInputChange({ target: { name: "ScheduledAnnualUpdate", value: (date ?? "") } }, )}
							renderInput={(params) => <TextField {...params} error={form?.ScheduledAnnualUpdate && form?.ScheduledAnnualUpdate?.trim()?.length > 1 ? Date.parse(form?.ScheduledAnnualUpdate) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
						/>
					</div>
					<div className="col-span-2 grid grid-cols-3 p-4 gap-4">
						<Autocomplete
							id="free-solo-demo"
							autoComplete={true}
							options={months}
							value={form?.RegistryMonth ?? ""}
							onChange={(event, newValue) => handleInputChange({ target: { name: "RegistryMonth", value: newValue as string } })}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Registry Month"
									variant="outlined"
									fullWidth
									sx={{
										marginTop: '10px',
										borderRadius: '8px', // Adjust as needed
										'& .MuiOutlinedInput-root': {
											borderRadius: '8px', // Ensures the input field is rounded
										},
									}}
								/>
							)}
						/>
						<TextField
							label="Supervision Status CSOSA"
							name="SupervisionStatusCSOSA"
							value={form?.SupervisionStatusCSOSA ?? ""}
							multiline
							style={{ marginTop: '10px' }}
							fullWidth
							onChange={handleInputChange}
							sx={{
								marginTop: '10px',
								borderRadius: '8px', // Adjust as needed
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px', // Ensures the input field is rounded
								},
							}}
						/>
						<DatePicker
							label="Supervision End Date CSOSA"
							value={form?.SupervisionEndDateCSOSA ?? ""}
							onChange={(date) => handleInputChange({ target: { name: "SupervisionEndDateCSOSA", value: (date ?? "") } })}
							renderInput={(params) => <TextField {...params} error={form?.SupervisionEndDateCSOSA && form?.SupervisionEndDateCSOSA?.trim()?.length > 1 ? Date.parse(form?.SupervisionEndDateCSOSA) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
						/>
					</div>
					<div className="col-span-2 flex items-center justify-center">
						<FormControl component="fieldset">
							<FormLabel component="legend" className="text-center">Arrest Warrant Submitted
							</FormLabel>
							<div className="mt-2 border border-gray-300 rounded-lg pl-4">
								<RadioGroup row className="mt-2 gap-x-16" aria-label="ArrestWarrantSubmitted"
									name="ArrestWarrantSubmitted"
									value={form?.ArrestWarrantSubmitted}
									onChange={handleInputChange}
									defaultValue={false}>
									<FormControlLabel value={true} control={<Radio />} label="Yes" />
									<FormControlLabel value={false} control={<Radio />} label="No" />
								</RadioGroup>
							</div>
						</FormControl>
					</div>
					<div className="col-span-1 row-span-1 p-4 gap-4">
						<TextField
							label="Cell Phone"
							name="CellPhone"
							value={form?.CellPhone ?? ""}
							multiline
							style={{ marginTop: '10px' }}
							fullWidth
							onChange={handleInputChange}
							sx={{
								marginTop: '10px',
								borderRadius: '8px', // Adjust as needed
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px', // Ensures the input field is rounded
								},
							}}
						/>
					</div>
					<div className="col-span-1 row-span-1 p-4 gap-4">
						<TextField
							label="Alt Phone"
							name="Altphone"
							value={form?.Altphone ?? ""}
							multiline
							style={{ marginTop: '10px' }}
							fullWidth
							onChange={handleInputChange}
							sx={{
								marginTop: '10px',
								borderRadius: '8px', // Adjust as needed
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px', // Ensures the input field is rounded
								},
							}}
						/>
					</div>
					<div className="col-span-1 row-span-1 p-4 gap-4">
						<DatePicker
							label="Action Date"
							value={form?.ActionDate ?? ""}
							onChange={(date) => handleInputChange({ target: { name: "ActionDate", value: (date ?? "") } })}
							renderInput={(params) => <TextField {...params} error={form?.ActionDate && form?.ActionDate?.trim()?.length > 1 ? Date.parse(form?.ActionDate) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
						/>
					</div>
					<div className="col-span-1 row-span-1 p-4 gap-4">
						<DatePicker
							label="Update Date"
							value={form?.UpdateDate ?? ""}
							onChange={(date) => handleInputChange({ target: { name: "UpdateDate", value: (date ?? "") } })}
							renderInput={(params) => <TextField {...params} error={form?.UpdateDate && form?.UpdateDate?.trim()?.length > 1 ? Date.parse(form?.UpdateDate) ? false : true : false} fullWidth
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>}
						/>
					</div>
					<div className="col-span-1 row-span-1 p-4 gap-4">
						<TextField
							label="Re-Registered By"
							name="ReregisteredBy"
							value={form?.ReregisteredBy ?? ""}
							multiline
							style={{ marginTop: '10px' }}
							fullWidth
							onChange={handleInputChange}
							sx={{
								marginTop: '10px',
								borderRadius: '8px', // Adjust as needed
								'& .MuiOutlinedInput-root': {
									borderRadius: '8px', // Ensures the input field is rounded
								},
							}}
						/>
					</div>
					<div className="col-span-1 row-span-1 gap-4 pr-4 pl-4 pb-4 flex items-center justify-center">
						<FormControl component="fieldset">
							<FormLabel component="legend" className="text-center">Change of Address
							</FormLabel>
							<div className="mt-2 border border-gray-300 rounded-lg pl-4">
								<RadioGroup row className="mt-2 gap-x-16" aria-label="ChangeOfAddress"
									name="ChangeOfAddress"
									value={form?.ChangeOfAddress}
									onChange={handleInputChange}
									defaultValue={false}>
									<FormControlLabel value={true} control={<Radio />} label="Yes" />
									<FormControlLabel value={false} control={<Radio />} label="No" />
								</RadioGroup>
							</div>
						</FormControl>
					</div>
					{form?.ChangeOfAddress === true && (
						<div className="col-span-2 p-4 gap-4">
							<TextField
								label="New Address"
								name="NewAddress"
								value={form?.NewAddress ?? ""}
								multiline
								style={{ marginTop: '10px' }}
								fullWidth
								onChange={handleInputChange}
								sx={{
									marginTop: '10px',
									borderRadius: '8px', // Adjust as needed
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px', // Ensures the input field is rounded
									},
								}}
							/>
						</div>
					)}
					<div className="col-span-2">
						<Divider />
					</div>
					<div className="col-span-2 text-center p-4">
						<Typography variant="h5">Notes</Typography>
					</div>
					<div className="col-span-2 row-span-2 p-4">
						<FormControl fullWidth>
							<InputLabel shrink htmlFor="Notes">Notes</InputLabel>
							<TextareaAutosize
								id="Notes"
								name="Notes"
								value={form?.Notes ?? ""}
								onChange={handleInputChange}
								style={{
									width: '100%', marginTop: '10px',
									border: '1px solid rgba(0, 0, 0, 0.23)',
									borderRadius: '4px',
									padding: '16.5px 14px',
									fontSize: '16px',
									lineHeight: '1.4375em',
									backgroundColor: 'inherit',
									resize: 'vertical'
								}}
								minRows={10}
							/>
						</FormControl>
					</div>
					<div className="col-span-2">
						<Divider />
					</div>
					<div className="col-span-2 p-4">
						<FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
							<TreeSelect value={cityTreeSelectValue} options={DDCity} filter dropdownIcon={"pi none"}
								resetFilterOnHide={true}
								onChange={(e) => {
									setCityTreeSelectValue(e.value || {});
									// Extract selected keys and filter out "BT_UNIQUE_GROUP*" keys
									const selectedKeys = Object.keys(e.value || {})
										.filter((key) => !key.includes("BT_UNIQUE_GROUP")) // Exclude unwanted keys
										.join(", "); // Convert to a single string

									handleInputChange(
										{ target: { name: "City", value: selectedKeys } } // Ensure it matches expected input format
									);
								}} selectionMode="checkbox" showClear
								placeholder={`${GetStringNameForSite(SiteName, 'CITY')}`}
								inputId={"dd-city"}
								style={{
									border: "1px solid #b9b9bb",
									height: "3.45rem",
									textAlign: "start",
									borderRadius: "8px", // Make the component rounded
								}}
								valueTemplate={(option: any) => getValueTemplate(option)}
							></TreeSelect>
							<label className={"bg-gray-100"} htmlFor="dd-city">{`${GetStringNameForSite(SiteName, 'CITY')}`}</label>
						</FormControl>
					</div>
					<div className="col-span-2 flex items-center justify-center p-4">
						<DropFileUploader originationProp={"Gun Offender"} onFileAdded={handleFile} />
					</div>
					<div className="col-span-2 flex items-center justify-center p-4">
						<FileHandler fileListProps={form.Files ?? []} onFileRemoved={handleFileRemove} />
					</div>
					<div className="col-span-2 flex items-right justify-right p-4">
						<Button onClick={handleSubmit} variant="contained" color="info">Submit</Button>
					</div>
			</div>
			</div>
		</LocalizationProvider>
	);
};

export default GunOffenderInput;