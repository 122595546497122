import React, {useState, useEffect} from "react";
import {convertUTCtoLocalTimeRange, formatDate, formatSSN, hideSSN} from "../../../services/formatDate.service"
import BTPersonGridImage from "../../shared/_PersonGridImage";
interface DetailProps {
    rpt: any | null

}


const M43ArrestReport: React.FC<DetailProps> = ({rpt}) => {

    const PersonImg = (person:any) => {
        let pQuery : any = {
            DOB: person?.dateOfBirth,
            FBI:  person?.fbiNumber,
            SBINo:  person?.stateIdNumber,
            FName:  person?.firstName,
            MName:  person?.middleName,
            LName:  person?.lastName

        }

        return (
            <BTPersonGridImage person={pQuery} />
        )
    }
    
    
    return (
        <div className="bg-white p-6 max-w-4xl mx-auto shadow-lg border border-gray-300 mt-10">
            <div className="bg-blue-900 text-white p-2 text-center">
                <h1 className="text-xl font-bold">CCN # {rpt.reportNumber} / ARREST # {rpt.recordNumber}</h1>
            </div>

            <div className="grid grid-cols-3">
                <div className="border-r border-b border-gray-300 pl-2">
                    <p className="font-bold text-xs	">REPORT DATE / TIME</p>
                    <p> {convertUTCtoLocalTimeRange(rpt.createdDateUtc,null)}</p>
                </div>
                <div className="border-r border-b border-gray-300 pl-2">
                    <p className="font-bold text-xs	">DISTRICT/PSA</p>
                    <p> {rpt?.externalArrest?.arrestLocation?.subdivision1}/{rpt?.externalArrest?.arrestLocation?.subdivision2}</p>
                </div>
                <div className="border-b border-gray-300 pl-2">
                    <p className="font-bold text-xs	">EVENT START DATE / TIME - EVENT END DATE /
                        TIME</p>
                    <p>{convertUTCtoLocalTimeRange(rpt.eventStartUtc,rpt.eventEndUtc)}</p>
                </div>
            </div>


            <div className="border-b border-gray-300">
                {/*<h2 className="font-bold text-base bg-blue-900 text-white p-2">Arrest Location</h2>*/}

                <div className="grid grid-cols-1">
                    <div className="border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">ARREST LOCATION NAME / STREET ADDRESS</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.placeName} {rpt?.externalArrest?.arrestLocation?.streetNumber} {rpt?.externalArrest?.arrestLocation?.streetName}</p>
                    </div>

                </div>
                <div className="grid grid-cols-4">
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">CITY</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.city}</p>
                    </div>
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">STATE</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.state}</p>
                    </div>
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">ZIP</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.zip}</p>
                    </div>
                    <div className="border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">COUNTRY</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.country}</p>
                    </div>
                </div>
                <div className="grid grid-cols-4">
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.crossStreet1}&nbsp;</p>
                    </div>
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.crossStreet2}&nbsp;</p>
                    </div>
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">LATITUDE</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.latitude}&nbsp;</p>
                    </div>
                    <div className="border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">LONGITUDE</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.longitude}&nbsp;</p>
                    </div>
                </div>
                <div className="grid grid-cols-3">
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.category}</p>
                    </div>
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">DISTRICT / PSA</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.subdivision1}/{rpt?.externalArrest?.arrestLocation?.subdivision2}</p>
                    </div>
                    <div className="border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                        <p>{rpt?.externalArrest?.arrestLocation?.locationPropertyType}</p>
                    </div>
                </div>

            </div>

            <div className="border-b border-gray-300">
                <h2 className="font-bold text-base bg-blue-900 text-white p-2">DEFENDANT</h2>

                <div className="grid grid-cols-6">
                    <div>{PersonImg(rpt?.externalArrest?.defendant)}&nbsp;</div>
                    <div className={"col-span-5"}>
                        <div className="grid grid-cols-2">
                            <div className="border-r border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">NAME (LAST, FIRST MIDDLE)</p>
                                <p>{rpt?.externalArrest?.defendant?.lastName}, {rpt?.externalArrest?.defendant?.firstName} {rpt?.externalArrest?.defendant?.middleName}&nbsp;</p>
                            </div>
                            <div className="border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">Date Of Birth</p>
                                <p>{rpt?.externalArrest?.defendant?.dateOfBirth}&nbsp;</p>
                            </div>

                        </div>
                        <div className="grid grid-cols-5">
                            <div className="border-r border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">PDID#</p>
                                <p>{rpt?.externalArrest?.defendant?.stateIdNumber}&nbsp;</p>
                            </div>

                            <div className="border-b border-r border-gray-300 pl-2">
                                <p className="font-bold text-xs	">FBI#</p>
                                <p>{rpt?.externalArrest?.defendant?.fbiNumber}&nbsp;</p>
                            </div>
                            <div className="border-r border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">DL#</p>
                                <p>{rpt?.externalArrest?.defendant?.licenseState} {rpt?.externalArrest?.defendant?.licenseNumber}&nbsp;</p>
                            </div>
                            <div className="border-r border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">SSN#</p>
                                <p>{rpt?.externalArrest?.defendant?.ssn}&nbsp;</p>
                            </div>

                            <div className="border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">Phone#</p>
                                <p>{rpt?.externalArrest?.defendant?.phoneNumber}&nbsp;</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-4">
                            <div className="border-r border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">SEX</p>
                                <p>{rpt?.externalArrest?.defendant?.sexDisplayName}&nbsp;</p>
                            </div>
                            <div className="border-r border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">EYE COLOR</p>
                                <p>{rpt?.externalArrest?.defendant?.eyeColorDisplayName}&nbsp;</p>
                            </div>
                            <div className="border-r border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">RACE</p>
                                <p>{rpt?.externalArrest?.defendant?.raceDisplayName}&nbsp;</p>
                            </div>
                            <div className=" border-b border-gray-300 pl-2">
                                <p className="font-bold text-xs	">ETHNICITY</p>
                                <p>{rpt?.externalArrest?.defendant?.ethnicityDisplayName}&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="grid grid-cols-1">
                    <div className="border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">HOME ADDRESS</p>
                        <p>{rpt?.externalArrest?.defendant?.homeAddress?.streetNumber} {rpt?.externalArrest?.defendant?.homeAddress?.streetName}</p>
                    </div>

                </div>
                <div className="grid grid-cols-4">
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">CITY</p>
                        <p>{rpt?.externalArrest?.defendant?.homeAddress?.city}</p>
                    </div>
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">STATE</p>
                        <p>{rpt?.externalArrest?.defendant?.homeAddress?.state}</p>
                    </div>
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">ZIP</p>
                        <p>{rpt?.externalArrest?.defendant?.homeAddress?.zip}</p>
                    </div>
                    <div className="border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">COUNTRY</p>
                        <p>{rpt?.externalArrest?.defendant?.homeAddress?.country}</p>
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                        <p>{rpt?.externalArrest?.defendant?.homeAddress?.crossStreet1}&nbsp;</p>
                    </div>
                    <div className="border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                        <p>{rpt?.externalArrest?.defendant?.homeAddress?.crossStreet2}&nbsp;</p>
                    </div>
                </div>
                <div className="grid grid-cols-3">
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                        <p>{rpt?.externalArrest?.defendant?.homeAddress?.category}</p>
                    </div>
                    <div className="border-r border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">DISTRICT / PSA</p>
                        <p>{rpt?.externalArrest?.defendant?.homeAddress?.subdivision1}/{rpt?.externalArrest?.defendant?.homeAddress?.subdivision2}</p>
                    </div>
                    <div className="border-b border-gray-300 pl-2">
                        <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                        <p>{rpt?.externalArrest?.defendant?.homeAddress?.locationPropertyType}</p>
                    </div>
                </div>


            </div>


            <div className="border-b border-gray-300">
                <h2 className="font-bold text-base bg-blue-900 text-white p-2">STATEMENT OF FACTS</h2>
                <p style={{overflow: 'auto'}}>
                    <pre className="text-xs" style={{whiteSpace: 'pre-wrap'}}>{rpt.narrative}</pre>
                </p>
            </div>

            {rpt?.externalArrest?.charges && rpt?.externalArrest?.charges.map((charge: any, idx: number) => (
                <div className="border-b border-gray-300">
                    <h2 className="font-bold text-base bg-blue-900 text-white p-2">CHARGE
                        - {idx + 1}</h2>
                    <div className="grid grid-cols-2">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs">OFFENSE CODE</p>
                            <p>{charge?.offense?.offenseCode}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold  text-xs">WEAPON / FORCE INVOLVED</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs">OFFENSE START DATE</p>
                            <p>
                                {convertUTCtoLocalTimeRange(charge?.offense?.offenseDateUtc,null)}
                            </p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs">OFFENSE END DATE</p>
                            <p> {convertUTCtoLocalTimeRange(charge?.offense?.offenseEndDateUtc,null)}</p>
                        </div>
                    </div>
                </div>
            ))}


            {/*<div className="border-b border-gray-300">*/}
            {/*    <h2 className="font-bold text-base bg-blue-900 text-white p-2">INVOLVED PROPERTY</h2>*/}
            {/*    <div className="grid grid-cols-3 ">*/}
            {/*        <div className="border-r border-b border-gray-300 pl-2">*/}
            {/*            <p className="font-bold text-xs">STATUS (NIBRS)</p>*/}
            {/*            <p></p>*/}
            {/*        </div>*/}
            {/*        <div className="border-r border-b border-gray-300 pl-2">*/}
            {/*            <p className="font-bold text-xs">ITEM CATEGORY</p>*/}
            {/*            <p></p>*/}
            {/*        </div>*/}
            {/*        <div className="border-b border-gray-300 pl-2">*/}
            {/*            <p className="font-bold text-xs">DESCRIPTION</p>*/}
            {/*            <p></p>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}

            {rpt?.involvedVehicles && rpt?.involvedVehicles?.map((veh: any, idx: number) => (<>
                <div className="border-b border-gray-300">
                    <h2 className="font-bold text-base bg-blue-900 text-white p-2">VEHICLE: {`${veh.make ?? ""} ${veh.model ?? ""} ${veh.yearOfManufacture ?? ""}`.toUpperCase()}</h2>


                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">ITEM CATEGORY</p>
                            <p>{veh.categoryFullName ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">MAKE</p>
                            <p>{veh.make ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">MODEL</p>
                            <p>{veh.model ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">YEAR OF MANUFACTURE</p>
                            <p>{veh.yearOfManufacture ?? ""}&nbsp;</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1">
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">DESCRIPTION
                            </p>
                            <p>{veh?.description?.toUpperCase()}</p>
                        </div>
                    </div>


                    <div className="grid grid-cols-3">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">LICENSE PLATE / REGISTRATION #</p>
                            <p>{veh?.licensePlateNumber?.toUpperCase()}</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">REGISTRATION TYPE</p>
                            <p>{veh?.registrationType?.toUpperCase()}</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">VIN #</p>
                            <p>{veh?.vinNumber?.toUpperCase()}</p>
                        </div>
                    </div>


                    <div className="grid grid-cols-4">
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">REGISTRATION STATE</p>
                            <p>{veh.registrationState ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">REGISTRATION YEAR</p>
                            <p>{veh.registrationYear ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-r border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INSURANCE COMPANY</p>
                            <p>{veh.insuranceProvider ?? ""}&nbsp;</p>
                        </div>
                        <div className="border-b border-gray-300 pl-2">
                            <p className="font-bold text-xs	">INSURANCE POLICY #</p>
                            <p>{veh.insurancePolicyNumber ?? ""}&nbsp;</p>
                        </div>
                    </div>

                    {veh?.linkedNames && veh?.linkedNames?.filter((p: any) => p?.linkedPerson?.lastName || p?.linkedPerson?.firstName)?.map((person: any, idx: number) => (
                        <div className="border-b border-gray-300">
                            <h2 className=" text-xs bg-blue-900 text-white p-2">VEHICLE OCCUPANT - {person?.nameItemAssociation ? person?.nameItemAssociation : "OTHER"}</h2>

                            <div className="grid grid-cols-6">
                                <div>{PersonImg(person?.linkedPerson)}&nbsp;</div>
                                <div className={"col-span-5"}>
                                    <div className="grid grid-cols-3">
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">NAME (LAST, FIRST
                                                MIDDLE)</p>
                                            <p>{person?.linkedPerson?.lastName?.toUpperCase()}{person?.linkedPerson?.firstName ? ',' : ''} {person?.linkedPerson?.firstName?.toUpperCase()} {person?.linkedPerson?.middleName?.toUpperCase()}&nbsp;</p>
                                        </div>
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">DATE OF BIRTH</p>
                                            <p>{person?.linkedPerson?.dateOfBirth}&nbsp;</p>
                                        </div>
                                        <div className="border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">DATE OF DEATH</p>
                                            <p>{formatDate(person?.linkedPerson?.dateOfDeath)}&nbsp;</p>
                                        </div>

                                    </div>
                                    <div className="grid grid-cols-5">
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">PDID#</p>
                                            <p>{person?.linkedPerson?.stateIdNumber}&nbsp;</p>
                                        </div>

                                        <div className="border-b border-r border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">FBI#</p>
                                            <p>{person?.linkedPerson?.fbiNumber}&nbsp;</p>
                                        </div>
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">DL#</p>
                                            <p>{person?.linkedPerson?.licenseState} {person?.linkedPerson?.licenseNumber}&nbsp;</p>
                                        </div>
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">SSN#</p>
                                            <p>{person?.linkedPerson?.ssn}&nbsp;</p>
                                        </div>

                                        <div className="border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">Phone#</p>
                                            <p>{person?.linkedPerson?.phoneNumber}&nbsp;</p>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-4">
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">SEX</p>
                                            <p>{person?.linkedPerson?.sexDisplayName?.toUpperCase()}&nbsp;</p>
                                        </div>
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">EYE COLOR</p>
                                            <p>{person?.linkedPerson?.eyeColorDisplayName?.toUpperCase()}&nbsp;</p>
                                        </div>
                                        <div className="border-r border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">RACE</p>
                                            <p>{person?.linkedPerson?.raceDisplayName?.toUpperCase()}&nbsp;</p>
                                        </div>
                                        <div className=" border-b border-gray-300 pl-2">
                                            <p className="font-bold text-xs	">ETHNICITY</p>
                                            <p>{person?.linkedPerson?.ethnicityDisplayName?.toUpperCase()}&nbsp;</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {person?.linkedPerson?.personInjuries && person?.linkedPerson?.personInjuries?.length > 0 && (
                                <div className="grid grid-cols-1">
                                    <div className=" border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs	">INJURIES</p>
                                        <p>
                                            <ul>
                                                {person?.linkedPerson?.personInjuries && person?.linkedPerson?.personInjuries?.map((injury: any) => (
                                                    <li>{injury?.injuryTypeName}, {injury?.bodyPartName}, {injury?.injuryDescription} </li>
                                                ))}
                                            </ul>
                                            &nbsp;
                                        </p>
                                    </div>
                                </div>
                            )}
                            {person?.linkedPerson?.identifyingMarks && person?.linkedPerson?.identifyingMarks?.length > 0 && (
                                <div className="grid grid-cols-1">
                                    <div className=" border-b border-gray-300 pl-2">
                                        <p className="font-bold text-xs	">IDENTIFYING MARKS</p>
                                        <p>
                                            <ul>
                                                {person?.linkedPerson?.identifyingMarks && person?.linkedPerson?.identifyingMarks?.map((marks: any) => (
                                                    <li>{marks?.identifyingMarkType}, {marks?.identifyingMarkLocation}, {marks?.identifyingMarkDescription} </li>
                                                ))}
                                            </ul>
                                            &nbsp;
                                        </p>
                                    </div>
                                </div>
                            )}

                            {person?.linkedPerson?.description && (<div className="grid grid-cols-1">
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DESCRIPTION</p>
                                    <p><pre>{person?.linkedPerson?.description}</pre>&nbsp;</p>
                                </div>
                            </div>)}


                            <div className="grid grid-cols-1">
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">HOME ADDRESS</p>
                                    <p>{person?.linkedPerson?.homeAddress?.streetNumber} {person?.linkedPerson?.homeAddress?.streetName?.toUpperCase()}</p>
                                </div>

                            </div>
                            <div className="grid grid-cols-4">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">CITY</p>
                                    <p>{person?.linkedPerson?.homeAddress?.city?.toUpperCase()}</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">STATE</p>
                                    <p>{person?.linkedPerson?.homeAddress?.state?.toUpperCase()}</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">ZIP</p>
                                    <p>{person?.linkedPerson?.homeAddress?.zip?.toUpperCase()}</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">COUNTRY</p>
                                    <p>{person?.linkedPerson?.homeAddress?.country?.toUpperCase()}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">INTERSECTION STREET 1</p>
                                    <p>{person?.linkedPerson?.homeAddress?.crossStreet1?.toUpperCase()}&nbsp;</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">INTERSECTION STREET 2</p>
                                    <p>{person?.linkedPerson?.homeAddress?.crossStreet2?.toUpperCase()}&nbsp;</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-4">
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">LOCATION CATEGORY</p>
                                    <p>{person?.linkedPerson?.homeAddress?.category}</p>
                                </div>
                                <div className="border-r border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">DISTRICT / PSA</p>
                                    <p>{person?.linkedPerson?.homeAddress?.subdivision1}/{person?.linkedPerson?.homeAddress?.subdivision2}</p>
                                </div>
                                <div className="border-b border-r border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">PUBLIC / PRIVATE</p>
                                    <p>{person?.linkedPerson?.homeAddress?.locationPropertyType}</p>
                                </div>
                                <div className="border-b border-gray-300 pl-2">
                                    <p className="font-bold text-xs	">Resident Status</p>
                                    <p>{person?.linkedPerson?.residentStatus}&nbsp;</p>
                                </div>
                            </div>

                        </div>
                    ))}


                </div>
            </>))}


        </div>
    )
}

export default M43ArrestReport;