import React, { useState } from "react";
import {
    DashboardRefresh,
    DashboardTimer,
    SiteName,
} from "../../../services/config.service";
import {
    GetStolenVehicleAgencyORI,
    RunGetDashboardRefreshToken,
    RunGetDashboardToken,
    RunGetStolenVehicleCountyCityList,
} from "../../../services/dashboard.service";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tooltip,
} from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import NJPortMap from "./_njPortMap";

import CheckerRequested from "./_njCheckerRequested";
import {
    GetDefaultStateForSite,
    GetStringNameForSite,
    SITE,
} from "../../../interfaces/config.interface";
import { Dropdown } from "primereact/dropdown";
import {
    InvestigationDropDown,
    getVolunteerHirePool,
} from "../../../services/njport.service";
import {
    VolunteerHireObject,
    VolunteerHirePoolResponse,
    VolunteerHireQuery,
} from "../../../interfaces/NJPORT/VolunteerHire.interface";
import { DropDown } from "../../../interfaces/NJPORT/InvestigationLog.interface";
import PDONotHired from "./njPDONotHired";
import VolunteerHired from "./njVolunteerHired";
import VolunteerNotHired from "./njVolunteerNotHired";
import VolunteerRequest from "./njVolunteerRequest";
import VolunteerHarboreHire from "./njVolunteerHarboreHire";
import LSRequested from "./_njLSRequested";
import VolunteerHireStats from "./volunteerHireStats";
import VolunteerHirePieChart from "./volunteerHirePieChart";
import PDOHireStats from "./pdoHireStats";
import PDOHireBarChart from "./pdoHireBarChart";
import PDOHirePieChart from "./pdoHirePieChart";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import VolunteerCrossHarbor from "./volunteerCrossHarbor";
import TerminalHireBarChart from "./terminalHireBarChart";
import ShipEntryBarChartMonthly from "./ShipEntryBarChartMonthly";
import PDOHireStackedBarChart from "./pdoHireStackedBarChart";

interface NJDashboardLayoutProps {
    GetStats: any;
    data: any;
}

var ToDate: Date = new Date();
var FromDate: Date = new Date();

const NJDashboardLayout: React.FC<NJDashboardLayoutProps> = ({
    GetStats,
    data,
}) => {
    const [RefreshCount, setRefreshCount] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [county, setCounty] = React.useState<string>("");
    const [city, setCity] = React.useState<string>("");
    const [agency, setAgency] = React.useState<string>("");
    const [dateUpdate, setDateUpdate] = React.useState<boolean>(true);
    let today = new Date();
    today.setHours(23);
    today.setMinutes(59);
    today.setSeconds(59);
    let sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    sevenDaysAgo.setHours(0);
    sevenDaysAgo.setMinutes(0);
    sevenDaysAgo.setSeconds(0);
    const [toDate, setToDate] = React.useState<any>(today);
    const [startDate, setStartDate] = React.useState<any>(sevenDaysAgo);
    const [initialLoad, setInitialLoad] = React.useState<boolean>(false);
    const [cityList, setCityList] = React.useState<any[]>([]);
    const [agencyList, setAgencyList] = React.useState<any[]>([]);
    const [allCityList, setAllCityList] = React.useState<any[]>([]);
    const [heatMapTab, setHeatMapTab] = React.useState("1");
    const [stolenTab, setStolenTab] = React.useState("1");
    const [recoveredTab, setRecoveredTab] = React.useState("1");
    const [top25Tab, setTop25Tab] = React.useState("1");
    const [sixHourTab, setSixHourTab] = React.useState("1");
    const [twentyFourHourTab, setTwentyFourHourTab] = React.useState("1");
    const [avgRecoveryTab, setAvgRecoveryTab] = React.useState("1");
    const [periodComparisonTab, setPeriodComparisonTab] = React.useState("1");
    const [countyList, setCountyList] = React.useState<any[]>([]);
    const [report, setReport] = React.useState<VolunteerHireObject>({});
    const [defaultTime, setDefaultTime] = React.useState<string>("");
    const [poolbegining, setDefaultPoolBegining] = React.useState<number>(0);
    const [defaultjobTilte, setDefaultjobTilte] = React.useState<string>("");
    const [timeDD, setTimeDD] = React.useState<DropDown[]>([]);
    const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
    const [shipTerminalDD, setShipTerminalDD] = React.useState<DropDown[]>([]);
    const [defaultstatus, setDefaultStatus] = React.useState<string>("");
    const [portDD, setPortDD] = React.useState<DropDown[]>([]);
    const [workForceTab, setWorkForeceTab] = React.useState("1");
    FromDate.setHours(0);
    FromDate.setMinutes(0);
    FromDate.setSeconds(0);
    ToDate.setHours(23);
    ToDate.setMinutes(59);
    ToDate.setSeconds(59);

    React.useEffect(() => {
        let params = {
            dateTypeValue: 1,
            dateRange: true,
            fromDate: startDate,
            toDate: toDate,
        };

        if (initialLoad) {
            GetStats(params);
        }
    }, [city, county, agency]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!loading) {
                let params = {
                    StartDate: startDate,
                    EndDate: toDate,
                    City: city && city.trim().length > 0 ? city : "ALL CITIES",
                    County: county && county.trim().length > 0 ? county : "ALL COUNTIES",
                    Agency: agency && agency.trim().length > 0 ? agency : "",
                    StolenType: "",
                    State:
                        (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)
                            ? "DC"
                            : "NJ",
                };
                GetStats(params);
                if (dateUpdate) {
                    let today = new Date();
                    setToDate(today);
                }
            }
        }, DashboardTimer * 6);        
        return () => clearInterval(interval);
    }, [city, toDate, startDate, county, agency]);

    React.useEffect(() => {
        const x = setInterval(async () => {
            let dashboard = await RunGetDashboardRefreshToken();
            if (dashboard !== null && dashboard?.AuthToken) {
                localStorage.setItem("dashboard", dashboard.AuthToken);
            }
        }, DashboardRefresh);
        return () => clearInterval(x);
    }, []);

    React.useEffect(() => {
        if (!initialLoad) {
            let temp: any = [];
            let tempC: any = [];
            let county: any = [];

            RunGetDashboardToken().then((dashboard) => {
                if (dashboard !== null && dashboard?.AuthToken) {
                    localStorage.setItem("dashboard", dashboard.AuthToken);

                    if (
                        !(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)
                    ) {
                        // RunGetStolenVehicleCountyCityList().then((cList) => {
                        //   //temp.push({City:"ALL CITIES"})
                        //   temp.push({
                        //     City:
                        //       "ALL " +
                        //       GetStringNameForSite(SiteName, "CITIES").toUpperCase(),
                        //   });
                        //   temp.push.apply(temp, cList);
                        //   setCityList(temp);
                        //   setAllCityList(temp);
                        //   //county.push({County:"ALL COUNTIES"})
                        //   county.push({
                        //     County:
                        //       "ALL " +
                        //       GetStringNameForSite(SiteName, "COUNTIES").toUpperCase(),
                        //   });
                        //   tempC = new Set();
                        //   for (let c of cList) {
                        //     tempC.add(c.County);
                        //   }
                        //   for (let c of Array.from(tempC)) {
                        //     county.push({ County: c });
                        //   }

                        //   setCountyList(county);
                        // });
                    } else {
                        // GetStolenVehicleAgencyORI(GetDefaultStateForSite(SiteName)).then(
                        //   (aList) => {
                        //     let temp = aList;
                        //     temp.push({ Key: "ALL AGENCIES", Value: "ALL AGENCIES" });
                        //     setAgencyList(temp);
                        //   },
                        // );
                    }

                    setLoading(false);
                    setCity("ALL CITIES");
                    setInitialLoad(true);
                    setCounty("ALL COUNTIES");
                    setAgency("ALL AGENCIES");
                }
            });
        }
    }, []);

    const handleAgencyChange = (a: string) => {
        if (a !== null) setAgency(a);
    };

    const getPeriodComparisonBoth = () => {
        if (data?.PeriodComparisonVehicles && data?.PeriodComparisonPlates) {
            let temp = [];
            for (let i = 0; i < data?.PeriodComparisonVehicles.length; i++) {
                for (let j = 0; j < data?.PeriodComparisonPlates.length; j++) {
                    if (
                        data?.PeriodComparisonVehicles[i].Type ===
                        data?.PeriodComparisonPlates[j].Type
                    ) {
                        temp.push({
                            Count:
                                data?.PeriodComparisonVehicles[i].Count +
                                data?.PeriodComparisonPlates[j].Count,
                            FromDate: data?.PeriodComparisonVehicles[i].FromDate,
                            ToDate: data?.PeriodComparisonVehicles[i].ToDate,
                            Type: data?.PeriodComparisonVehicles[i].Type,
                        });
                    }
                }
            }
            return temp;
        }
        return [];
    };

    const getPercentageChange = (category: string) => {
        switch (category) {
            case "Vehicle":
                if (
                    "PeriodComparisonVehicles" in data &&
                    Array.isArray(data.PeriodComparisonVehicles)
                ) {
                    if (data.PeriodComparisonVehicles.length > 1) {
                        if (data?.PeriodComparisonVehicles[1]?.Count === 0)
                            return data.PeriodComparisonVehicles[0]?.Count;

                        let decrease =
                            data.PeriodComparisonVehicles[0]?.Count -
                            data.PeriodComparisonVehicles[1]?.Count;
                        let change =
                            (decrease / data.PeriodComparisonVehicles[1]?.Count) * 100;
                        return Math.round(change * 100) / 100;
                    }
                }
                break;
            case "Plate":
                if (
                    "PeriodComparisonPlates" in data &&
                    Array.isArray(data.PeriodComparisonPlates)
                ) {
                    if (data.PeriodComparisonPlates.length > 1) {
                        if (data?.PeriodComparisonPlates[1]?.Count === 0)
                            return data.PeriodComparisonPlates[0]?.Count;

                        let decrease =
                            data.PeriodComparisonPlates[0]?.Count -
                            data.PeriodComparisonPlates[1]?.Count;
                        let change =
                            (decrease / data.PeriodComparisonPlates[1]?.Count) * 100;
                        return Math.round(change * 100) / 100;
                    }
                }
                break;
            case "Both":
                if (
                    "PeriodComparisonVehicles" in data &&
                    Array.isArray(data.PeriodComparisonVehicles)
                ) {
                    if (data.PeriodComparisonVehicles.length > 1) {
                        if (
                            data?.PeriodComparisonVehicles[1]?.Count +
                            data?.PeriodComparisonPlates[1]?.Count ===
                            0
                        )
                            return (
                                data.PeriodComparisonVehicles[0]?.Count +
                                data.PeriodComparisonPlates[0]?.Count
                            );

                        let decrease =
                            data.PeriodComparisonVehicles[0]?.Count +
                            data.PeriodComparisonPlates[0]?.Count -
                            (data.PeriodComparisonVehicles[1]?.Count +
                                data.PeriodComparisonPlates[1]?.Count);
                        let change =
                            (decrease /
                                (data.PeriodComparisonVehicles[1]?.Count +
                                    data.PeriodComparisonPlates[1]?.Count)) *
                            100;
                        return Math.round(change * 100) / 100;
                    }
                }
                break;
        }
    };

    const handleTabChange = (category: string, newValue: string) => {
        switch (category) {
            case "stolen":
                setStolenTab(newValue);
                break;
            case "recovered":
                setRecoveredTab(newValue);
                break;
            case "top25":
                setTop25Tab(newValue);
                break;
            case "HeatMap":
                setHeatMapTab(newValue);
                break;
            case "sixHour":
                setSixHourTab(newValue);
                break;
            case "twentyFourHour":
                setTwentyFourHourTab(newValue);
                break;
            case "avgRecovery":
                setAvgRecoveryTab(newValue);
                break;
            case "periodComparison":
                setPeriodComparisonTab(newValue);
                break;
        }
    };

    function compareDatesByComponents(date1: Date, date2: Date): number {
        const yearDiff = date1.getFullYear() - date2.getFullYear();
        const monthDiff = date1.getMonth() - date2.getMonth();
        const dayDiff = date1.getDate() - date2.getDate();

        if (yearDiff !== 0) {
            return yearDiff;
        }

        if (monthDiff !== 0) {
            return monthDiff;
        }

        return dayDiff;
    }

    React.useEffect(() => {
        portLocationDropDown();
        portTerminalDropDown();
    }, []);

    const portLocationDropDown = async () => {
        let data = {
            params: {
                DropDownName: "PORT_LOCATION",
                // State: "NJ",
            },
        };
        let response: DropDown[] = await InvestigationDropDown(data);
        setPortDD(response);
    };

    const portTerminalDropDown = async () => {
        let data = {
            params: {
                DropDownName: "PORT_SHIPTERMINAL_NEWYORK",
            },
        };
        let response: DropDown[] = await InvestigationDropDown(data);
        setShipTerminalDD(response);
    };

    const startPool = async (val: any) => {
        let search_query: VolunteerHireQuery = {
            queryType: "VOLUNTEERHIRE_STARTPOOL",
            dateTypeValue: 1,
            dateRange: true,
            fromDate: val + "T00:00:00",
            toDate: val + "T23:59:59",
        };
        let result: VolunteerHirePoolResponse = await getVolunteerHirePool(
            search_query,
        );
        if (result && result.AnyTable && result.AnyTable.length > 0) {
            if (result.AnyTable[0].StartPool) {
                report.PoolBiginning = result.AnyTable[0].StartPool;
                setDefaultPoolBegining(result.AnyTable[0].StartPool);
            }
        }
    };

    const terminalDropDown = async (port: any) => {
        let data = {
            params: {
                DropDownName: "PORT_SHIPTERMINAL" + "_" + port,
                State: "NJ",
            },
        };
        let response: DropDown[] = await InvestigationDropDown(data);
        setShipTerminalDD(response);
    };

    const handleValueChange = (field: string, val: any) => {
        let temp = { ...report };
        switch (field) {
            case "id":
                temp.ID = val;
                break;
            case "caseNo":
                temp.CaseNo = val;
                break;
            case "port":
                temp.Port = val;
                // setDefaultStatus(portDD.find((d) => d.Key === val)?.Value ?? "");
                break;
            case "hiredate":
                temp.HiringDateTime = val;
                startPool(val);
                break;
            case "hiretime":
                temp.HiringTime = temp.HiringTime + "T" + val + "Z";
                setDefaultTime(timeDD.find((d) => d.Key === val)?.Value ?? "");
                break;
            case "jobTitle":
                temp.Job_Code = val;
                setDefaultjobTilte(jobTitleDD.find((d) => d.Key === val)?.Value ?? "");
                break;
            case "poolBeginning":
                temp.PoolBiginning = val;
                setDefaultPoolBegining(val);
                break;
            case "CompanyHiring":
                temp.CompanyHiring = val ? parseInt(val) : 0;
                temp.RequestsFilled =
                    parseInt(val) + (temp.THEICHiring ? temp.THEICHiring : 0);
                break;
            case "THEIC":
                temp.THEICHiring = val ? parseInt(val) : 0;
                temp.RequestsFilled =
                    parseInt(val) + (temp.CompanyHiring ? temp.CompanyHiring : 0);
                break;
            case "shipTerminal":
                temp.ShipTerminal = val;
                setDefaultStatus(
                    shipTerminalDD.find((d) => d.Key === val)?.Value ?? "",
                );
                break;
            case "Remaining":
                temp.Remaining = val;
                break;
            case "Unfilled":
                temp.Unfilled = val;
                break;
            case "JobRequests":
                temp.JobRequests = val;
                break;

            case "portLocation":
                temp.Port = val;
                terminalDropDown(temp.Port);
                break;
        }
        setReport(temp);
    };

    const handleRefreshCount = () => {
        FromDate.setHours(0);
        FromDate.setMinutes(0);
        FromDate.setSeconds(0);
        ToDate.setHours(23);
        ToDate.setMinutes(59);
        ToDate.setSeconds(59);
        setRefreshCount(c => c + 1)
        HandleRefreshCount()
    }

    const HandleRefreshCount = () => {
        setRefreshCount(c => c + 1)
    }

    return (
        <div>
            {!loading ? (
                <div>
                    <div
                        className="text-center text-black font-bold mt-2"
                        style={{ fontSize: 26 }}
                    >
                        <span>PORT Hiring DASHBOARD</span>
                    </div>
                    <div className="mt-3">
                        <div className="my-4 mx-6 grid grid-cols-12 gap-x-4 justify-items-center">
                            <div className="col-span-3" style={{ width: "100%" }}>
                                <div
                                    className="border-gray-900 border-2 h-auto"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="PDO Hire Stats"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            PDO Hire Stats ( {ToDate.toLocaleDateString('en-US')} )
                                        </div>
                                    </Tooltip>
                                    <PDOHireStats HandleRefreshCount={HandleRefreshCount} RefreshCount={RefreshCount} StartDate={FromDate} EndDate={ToDate} />
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Checkers Hiring Today"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            Checkers Hiring ( {ToDate.toLocaleDateString('en-US')} )
                                        </div>
                                    </Tooltip>
                                    <PDOHireStackedBarChart HandleRefreshCount={HandleRefreshCount} RefreshCount={RefreshCount} StartDate={FromDate} EndDate={ToDate} />
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto  mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Pie chart data for volunteer hire"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">
                                            PDO Checker Requests (30 Day Average)
                                        </div>
                                    </Tooltip>
                                    <div className="">
                                        <PDOHirePieChart />
                                    </div>
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto  mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Average number of days to recover a Port Hiring"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            PDO Hire (Weekly)
                                        </div>
                                    </Tooltip>
                                    <div className="pt-4">
                                        <PDOHireBarChart EndDate={ToDate} />
                                    </div>
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto  mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="A comparison of Port Hiring based off the interval between Start and End date "
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            Daily Workforce Ordered (Weekly)
                                        </div>
                                    </Tooltip>

                                    <TabContext value={workForceTab}>
                                        <TabList
                                            onChange={(e, value) => setWorkForeceTab(value)}
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                        >
                                            <Tab
                                                label="Daily Workforce Ordered"
                                                value={"1"}
                                                style={{ fontSize: "12px" }}
                                            />
                                            <Tab
                                                label="NJ Longshoremen Not Ordered"
                                                value={"2"}
                                                style={{ fontSize: "12px" }}
                                            />
                                        </TabList>
                                        <TabPanel value={"1"} style={{ padding: "5px" }}>
                                            <VolunteerHired EndDate={ToDate} />
                                        </TabPanel>
                                        <TabPanel value={"2"} style={{ padding: "5px" }}>
                                            <PDONotHired EndDate={ToDate} />
                                        </TabPanel>
                                    </TabContext>
                                </div>

                            </div>

                            <div className="col-span-6" style={{ width: "100%" }}>
                                <div
                                    className="border-gray-900 border-2 mb-4 "
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Filter the dashboards data to a specific County and City"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">
                                            Dashboard Filters
                                        </div>
                                    </Tooltip>
                                    {!(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && (
                                            <div className="grid grid-cols-12 gap-4 p-2">
                                                <div className="col-span-6">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="port">Port</InputLabel>
                                                        <Select
                                                            labelId="port"
                                                            id="Port"
                                                            value={report.Port}
                                                            label="Port"
                                                            onChange={(e: any) =>
                                                                handleValueChange("portLocation", e.target.value)
                                                            }
                                                        >
                                                            {portDD.map((d) => (
                                                                <MenuItem key={d.Key} value={d.Key}>
                                                                    {d.Value}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="col-span-6">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="invStatus-Label">
                                                            Ship Terminal
                                                        </InputLabel>
                                                        <Select
                                                            labelId="invStatus-Label"
                                                            id="Ship Terminal"
                                                            value={defaultstatus}
                                                            renderValue={() => defaultstatus}
                                                            label="InvStatus"
                                                            onChange={(e: any) =>
                                                                handleValueChange("shipTerminal", e.target.value)
                                                            }
                                                        >
                                                            {shipTerminalDD.map((d) => (
                                                                <MenuItem key={d.Key} value={d.Key}>
                                                                    {d.Value}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        )}
                                    {(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) && (
                                            <div className="col-span-4">
                                                <FormControl
                                                    className="p-float-label"
                                                    sx={{ width: "100%" }}
                                                >
                                                    <Dropdown
                                                        value={agency}
                                                        onChange={(e: any) => {
                                                            handleAgencyChange(e.value);
                                                        }}
                                                        style={{
                                                            border: "1px solid #b9b9bb",
                                                            height: "2.75rem",
                                                            textAlign: "start",
                                                        }}
                                                        resetFilterOnHide={true}
                                                        inputId={"dd-state"}
                                                        options={agencyList}
                                                        filter
                                                        optionLabel="Value"
                                                        optionValue="Key"
                                                        placeholder="Agency"
                                                        className="w-full md:w-20rem"
                                                    />
                                                    <label className={"bg-white"} htmlFor="dd-state">
                                                        Agency
                                                    </label>
                                                </FormControl>
                                            </div>
                                        )}
                                    <div className="grid grid-cols-12 gap-4 p-2">
                                        <div
                                            className={(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)
                                                    ? "col-span-4"
                                                    : "col-span-6"
                                            }
                                        >
                                            <DatePickerComponent
                                                cssClass="e-outline"
                                                strictMode={true}
                                                value={startDate}
                                                onBlur={(date: any) => {
                                                    compareDatesByComponents(
                                                        startDate,
                                                        new Date(date.target.value),
                                                    )
                                                        ? setStartDate(new Date(date.target.value))
                                                        : console.log("");

                                                    let params = {
                                                        dateTypeValue: 1,
                                                        dateRange: true,
                                                        fromDate: new Date(date.target.value),
                                                        toDate: toDate,
                                                    };

                                                    handleRefreshCount();
                                                    GetStats(params);
                                                    FromDate = new Date(ToDate)
                                                }}
                                                onChange={(date: any) => {
                                                    date.value
                                                        ? setStartDate(date.value)
                                                        : setStartDate(startDate);

                                                    let params = {
                                                        dateTypeValue: 1,
                                                        dateRange: true,
                                                        fromDate: new Date(date.target.value),
                                                        toDate: toDate,
                                                    };

                                                    handleRefreshCount();
                                                    GetStats(params);
                                                    FromDate = new Date(ToDate)
                                                }}
                                                floatLabelType="Auto"
                                                placeholder="Start Date"
                                            />
                                        </div>
                                        <div
                                            className={(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)
                                                    ? "col-span-4"
                                                    : "col-span-6"
                                            }
                                        >
                                            <DatePickerComponent
                                                cssClass="e-outline"
                                                value={toDate}
                                                strictMode={true}
                                                onBlur={(date: any) => {
                                                    compareDatesByComponents(
                                                        toDate,
                                                        new Date(date.target.value),
                                                    ) ? setToDate(new Date(date.target.value))
                                                        : console.log("");

                                                    date.value
                                                        ? setToDate(date.target.value)
                                                        : setToDate(toDate);

                                                    ToDate = new Date(date.target.value)

                                                    let params = {
                                                        dateTypeValue: 1,
                                                        dateRange: true,
                                                        fromDate: startDate,
                                                        toDate: ToDate,
                                                    };

                                                    handleRefreshCount();
                                                    GetStats(params);
                                                    FromDate = new Date(ToDate)
                                                }}

                                                onChange={(date: any) => {
                                                    date.value
                                                        ? setToDate(date.value)
                                                        : setToDate(toDate);

                                                    ToDate = new Date(date.value)

                                                    let params = {
                                                        dateTypeValue: 1,
                                                        dateRange: true,
                                                        fromDate: startDate,
                                                        toDate: ToDate,
                                                    };
                                                    handleRefreshCount();
                                                    GetStats(params);
                                                    FromDate = new Date(ToDate)
                                                }}
                                                floatLabelType="Auto"
                                                placeholder="End Date"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="border-gray-900 border-2 mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Heatmap locations are plotted on the center of the municipality boundar"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">
                                            Location Data
                                        </div>
                                    </Tooltip>
                                    {/* AVG Recovery Time */}
                                    <div className="px-2 pb-2">
                                        <NJPortMap
                                            data={data?.njPortData?.AnyTable}
                                            county={county}
                                            city={city}
                                            tab={heatMapTab}
                                        />
                                    </div>
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Average number of days to recover a Port Hiring"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            Ship Entry (Monthly)
                                        </div>
                                    </Tooltip>
                                    <div className="pt-4">
                                        <ShipEntryBarChartMonthly />
                                    </div>
                                </div>

                            </div>

                            <div className="col-span-3 " style={{ width: "100%" }}>
                                <div
                                    className="border-gray-900 border-2 h-auto "
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Volunteer Hiring Statistics (Today/Date)"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            Volunteer Hiring Statistics ( {ToDate.toLocaleDateString('en-US')} ) 
                                        </div>
                                    </Tooltip>
                                    <VolunteerHireStats HandleRefreshCount={HandleRefreshCount} RefreshCount={RefreshCount} StartDate={FromDate} EndDate={ToDate} />
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto  mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Volunteer Hire (Monthly)"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">
                                            Volunteer Hiring by Hiring Period
                                        </div>
                                    </Tooltip>
                                    <VolunteerHirePieChart />
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Average number of days to recover a Port Hiring"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            Terminal Hire (Daily)
                                        </div>
                                    </Tooltip>
                                    <div className="pt-4">
                                        <TerminalHireBarChart HandleRefreshCount={HandleRefreshCount} RefreshCount={RefreshCount} StartDate={FromDate} EndDate={ToDate} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default NJDashboardLayout;
